import './index.css'
import moment from 'moment'
import Cookies from 'js-cookie'
import { useRef, useEffect, useState } from 'react'
import { MagnifyingGlassIcon, PencilIcon, PlusIcon, ArrowLeftIcon, ArrowRightIcon, TrashIcon, ArrowDownCircleIcon, CheckIcon } from '@heroicons/react/24/outline'

const TableDeferimento = ({ states, headers, dataTable, next, previous, infoTable, title, description, edit, add, search, deleteDefer, defired }) => {

    const nextPage = () => {
        next()
    }

    const previousPage = () => {
        previous()
    }


    const dowloadFile = (base64String, filename) => {
        const link = document.createElement("a");
        link.href = `data:application/pdf;base64,${base64String}`;
        link.download = filename;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    const trGen = () => {
        if (states.isLoading) {
            return (
                <tr className="hover:bg-gray-100">
                    {
                        dataTable.length > 0 && Object.keys(dataTable[0]).map((key, index) => {
                            return (

                                <td className="px-6 py-4 bg-white px-8 py-6 whitespace-pre-wrap text-sm text-gray-500" >
                                    <div className="flex items-center justify-center">
                                        <div className="h-6 w-6 animate-spin rounded-full border-t-2 border-b-2 border-gray-500"></div>
                                    </div>
                                </td>
                            )
                        })
                    }
                </tr>
            )

        } else {
            return (
                dataTable.length > 0 ? (
                    dataTable.map((logs) => {
                        return (
                            <tr key={logs.id} className="hover:bg-gray-200">
                                <td className="px-8 py-6 whitespace-nowrap text-right flex items-center space-x-2">
                                    <button className="h-10 w-10 text-gray-400 hover:text-gray-600" onClick={() => edit(logs)}>
                                        <PencilIcon className="h-7 w-7" />
                                    </button>
                                    {
                                        (Cookies.get('roleJuri') === 'LAWYER') &&
                                        <>
                                            <button className="h-10 w-10 text-gray-400 hover:text-gray-600" onClick={() => deleteDefer(logs)}
                                            >
                                                <TrashIcon className="h-7 w-7" />
                                            </button>
                                            {
                                                logs.outcome == null && (
                                                    <button className="h-10 w-10 text-gray-400 hover:text-gray-600" onClick={() => defired(logs)}
                                                    >
                                                        <CheckIcon className="h-7 w-7" />
                                                    </button>
                                                )
                                            }
                                        </>
                                    }
                                    {
                                        logs.filename != null && (
                                            <button className="h-10 w-10 text-gray-400 hover:text-gray-600" onClick={() => dowloadFile(logs.fileBase64, logs.filename)}
                                            >
                                                <ArrowDownCircleIcon className="h-7 w-7" />
                                            </button>
                                        )
                                    }
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-center  text-gray-900">
                                    {logs.motionType}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-center  text-gray-900">
                                    {
                                        logs.description
                                    }
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-center  text-gray-900">
                                    {
                                        logs.isPublic == 1 ? <p className="text-green-500">Público</p> : <p className="text-red-500">Privado</p>
                                    }
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-center  text-gray-900">
                                    {
                                        logs.judgeName
                                    }
                                </td>
                                <td className="px-8 py-4 whitespace-nowrap text-sm text-center  text-gray-900">
                                    {logs.filedBy}
                                </td>
                                <td className="px-8 py-4 whitespace-nowrap text-sm text-center  text-gray-900">
                                    {
                                        logs.lawFirm ? (
                                            logs.lawFirm
                                        ) : logs.lawyerName ? (
                                            logs.lawyerName
                                        ) :
                                            ("Ação sem escritório ou advogado vinculado")
                                    }
                                </td>
                                <td className="px-8 py-4 whitespace-nowrap text-sm text-center  text-gray-900">
                                    {logs.outcome == null ? "Aguardando" : logs.outcome == 'defired' ? "Deferido" : "Indeferido"}
                                </td>
                                <td className="px-8 py-4 whitespace-nowrap text-sm text-center  text-gray-900">
                                    {moment(logs.filedDate).format('DD/MM/YYYY')}
                                </td>
                                <td className="px-10 py-4 whitespace-nowrap text-sm text-center  text-gray-900">
                                    {logs.decisionDate == null ? "Aguardando" : moment(logs.decisionDate).format('DD/MM/YYYY')}
                                </td>
                                <td className="px-12 py-4 whitespace-nowrap text-sm text-center  text-gray-900">
                                    {moment(logs.updated_at).format('DD/MM/YYYY')}
                                </td>


                            </tr> // git add . && git commit -m "painel processo" && git push 
                        )
                    }
                    )
                ) : (
                    <tr>
                        <td className="px-8 py-6 whitespace-nowrap text-sm text-center text-gray-500" colSpan="5">
                            Sem registro de dados
                        </td>
                    </tr>
                )
            )

        }
    }

    const scrollContainerRef = useRef(null);
    const [showScrollButtons, setShowScrollButtons] = useState(false);
    const scrollTable = (direction) => {
        if (scrollContainerRef.current) {
            const scrollAmount = 200; // A quantidade de pixels para rolar
            const currentScroll = scrollContainerRef.current.scrollLeft;
            scrollContainerRef.current.scrollLeft = direction === 'left' ? currentScroll - scrollAmount : currentScroll + scrollAmount;
        }
    };
    useEffect(() => {
        const checkOverflow = () => {
            const container = scrollContainerRef.current;
            if (container) {
                const isOverflowing = container.scrollWidth > container.clientWidth;
                setShowScrollButtons(isOverflowing);
            }
        };

        // Verifica o overflow quando o componente é montado
        checkOverflow();

        // Adiciona um ouvinte para verificar o overflow quando a janela é redimensionada
        window.addEventListener('resize', checkOverflow);

        // Remove o ouvinte quando o componente é desmontado
        return () => window.removeEventListener('resize', checkOverflow);
    }, []);

    return (
        <div className="mt-4">
            {showScrollButtons && (
                <div className="flex justify-between">
                    <button
                        onClick={() => scrollTable('left')}
                        className="border border-blue-500 hover:bg-blue-100 text-white font-bold py-2 px-4 rounded rounded-full transition-transform duration-300 hover:-translate-y-1"
                    >
                        <ArrowLeftIcon className="h-7 w-7 text-blue-500" />
                    </button>
                    <button
                        onClick={() => scrollTable('right')}
                        className="border border-blue-500 hover:bg-blue-100 text-white font-bold py-2 px-4 rounded rounded-full transition-transform duration-300 hover:-translate-y-1"
                    >
                        <ArrowRightIcon className="h-7 w-7 text-blue-500" />
                    </button>
                </div>
            )}
            <div className="mt-4 overflow-x-auto" ref={scrollContainerRef}>
                <div className="inline-block min-w-full  rounded-md border border-gray-200">

                    <div className="rounded-md flex justify-between items-center px-8 py-6 bg-white">
                        <div className="flex flex-1 items-center">
                            {
                                (Cookies.get('roleJuri') === 'LAWYER' || Cookies.get('roleJuri') === 'AUXLAWYER') &&
                                <button
                                    onClick={() => add()}
                                    className="flex items-center justify-center h-10 w-10 text-gray-500 rounded-md border hover:bg-gray-200 focus-visible:outline-offset-0"
                                >
                                    <PlusIcon className="h-12 w-12" />
                                </button>
                            }
                            <div className="flex flex-col ml-4">
                                <h1 className="text-xl font-bold text-gray-700">{title}</h1>
                                <p className="text-gray-500">{description}</p>
                            </div>
                        </div>
                    </div>
                    <table className="min-w-full divide-y divide-gray-300">
                        <thead className="bg-gray-50">
                            <tr>
                                {
                                    headers.map((headers, index) => (
                                        <th key={index} scope="col" className="px-8 py-4 text-left text-sm text-center font-semibold text-gray-900">
                                            {headers}
                                        </th>
                                    ))
                                }
                            </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                            {
                                trGen()
                            }
                        </tbody>
                    </table>
                    <nav className="flex items-center justify-between border-t border-gray-200 bg-white py-6 px-8">
                        <div className="flex flex-1 justify-start">
                            <button
                                className="border border-gray-200 relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-700 rounded-md hover:bg-gray-200 focus-visible:outline-offset-0"
                                onClick={() => previousPage()}
                            >
                                Anterior
                            </button>
                            <button
                                href="#"
                                className="border border-gray-200 relative ml-3 inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-700 rounded-md hover:bg-gray-200 focus-visible:outline-offset-0"
                                onClick={() => nextPage()}
                            >
                                Próximo
                            </button>
                        </div>
                    </nav>
                </div>
            </div>
        </div>
    )

}

export default TableDeferimento;