import { useState, useEffect } from "react"
import { XMarkIcon, PlusIcon, PencilIcon } from '@heroicons/react/20/solid'
import AdicaoAutorizacao from "../adicaoAutorizacao"


const AdicaoProcesso = ({ states, setters, adicao, isEdit, processo, processos, edit, acao, truncateText, tribunais, varas, getVara, getVaraForm }) => {
    // console.log(varas)

    const [motion, setMotion] = useState({
        action_id: acao.id,
        isPublic: '',
        number: '',
        caseTitle: '',
        caseDescription: '',
        caseType: '',
        prosecutor: '',
        defendant: '',
        status: '',
        court_id: '',
        courtSection: '',
        judge: '',
        notes: '',
        filingDate: ''
    })

    const [error, setError] = useState({
        number: false,
        caseTitle: false,
        caseDescription: false,
        caseType: false,
        prosecutor: false,
        defendant: false,
        status: false,
        court_id: false,
        courtSection: false,
        judge: false,
        notes: false,
        filingDate: false,
        isPublic: false
    })

    const [canInsert, setCanInsert] = useState(false)

    const [openAuthorization, setOpenAuthorization] = useState(false)
    const [authorization, setAuthorization] = useState({})
    const [isEditAuthorization, setIsEditAuthorization] = useState(false)


    const addAutorizacao = e => {
        if (isEditAuthorization) {
            setAuthorization(e)
            setIsEditAuthorization(false)
            setOpenAuthorization(false)

        } else {
            setAuthorization(e)
            setOpenAuthorization(false)
        }
    }

    const deleteAutorizacao = e => {
        setAuthorization({})
    }

    const updateAuthorization = e => {
        setAuthorization(e)
        setIsEditAuthorization(true)
        setOpenAuthorization(true)
    }

    const editAutorizacao = e => {
        setAuthorization(e)
        setIsEditAuthorization(false)
        setOpenAuthorization(false)
    }

    // console.log(acao)

    //Se nao tiver notas, deve colocar automaticaeamente "Sem notas"
    const saveMotion = () => {
        let errorProcess = false;
        for (const item of Object.keys(motion)) {
            if (item === 'file') continue;
            if ((motion[item] === '' || motion[item] === ' ' || motion[item] === undefined) && (motion[item] !== false && motion[item] !== true)) {
                setError(e => {
                    return { ...e, [item]: true }
                })
                errorProcess = true
            } else {
                setError(e => {
                    return { ...e, [item]: false }
                })
            }
        }
        if (acao.auth === 1) {
            if (Object.keys(authorization).length === 0) {
                errorProcess = true
            }
        }
        if (errorProcess) return;
        setCanInsert(true)
        const courtSectionName = varas.filter(vara => vara.id === motion.courtSection)
        if (isEdit) {
            edit({
                id: processo.id,
                action_id: acao.id,
                number: motion.number,
                caseTitle: motion.caseTitle,
                caseDescription: motion.caseDescription,
                caseType: motion.caseType,
                prosecutor: motion.prosecutor,
                defendant: motion.defendant,
                status: motion.status,
                court_id: motion.court_id,
                courtSection: motion.courtSection,
                courtSectionName: courtSectionName[0].name,
                judge: motion.judge,
                notes: motion.notes,
                filingDate: motion.filingDate,
                isPublic: motion.isPublic,
                authorization: authorization
            })
            return;
        }
        let identifier = 1;
        if (processos.length > 0) {
            const haveThis = processos.filter(e => e.id === identifier)
            if (haveThis.length > 0) {
                let haveId = true;
                while (haveId) {
                    identifier++;
                    const haveThis = processos.filter(e => e.id === identifier)
                    if (haveThis.length === 0) {
                        haveId = false;
                    }
                }
            }
        }
        adicao({
            id: identifier,
            action_id: acao.id,
            number: motion.number,
            caseTitle: motion.caseTitle,
            caseDescription: motion.caseDescription,
            caseType: motion.caseType,
            prosecutor: motion.prosecutor,
            defendant: motion.defendant,
            status: motion.status,
            court_id: motion.court_id,
            courtSection: motion.courtSection,
            courtSectionName: courtSectionName[0].name,
            judge: motion.judge,
            notes: motion.notes,
            filingDate: motion.filingDate,
            isPublic: motion.isPublic,
            authorization: authorization
        })
        setMotion({
            action_id: acao.id,
            isPublic: '',
            number: '',
            caseTitle: '',
            caseDescription: '',
            caseType: '',
            prosecutor: '',
            defendant: '',
            status: '',
            court_id: '',
            courtSection: '',
            judge: '',
            notes: '',
            filingDate: ''
        })
        setAuthorization({})

    }

    const moreVaras = () => {
        setters.setOffsetJuriVaras(states.offsetJuriVaras + states.limitJuriVaras)
    }

    const moreTribunais = () => {
        setters.setOffsetJuriTribunais(states.offsetJuriTribunais + states.limitJuriTribunais)
    }

    const changeTribunal = async (tribunal) => {
        if (tribunal !== '' && tribunal !== 'more') {
            setters.setLimitSearchJuriVaras(false)
            setters.setOffsetJuriVaras(0)
            const resp = await getVara(tribunal)
            if (!resp) {
                setters.setDataJuriVaras([]);
            }
            setMotion({ ...motion, court_id: tribunal })
        }
        if (tribunal === 'more') {
            moreTribunais()
            setMotion({ ...motion, court_id: '', courtSection: '' })
        }
    };

    const changeVara = async (vara) => {
        if (vara !== '' && vara !== 'more') {
            setMotion({ ...motion, courtSection: vara })
        }
        if (vara === 'more') {
            moreVaras()
            setMotion({ ...motion, courtSection: '' })
        }
    }


    useEffect(() => {
        if (isEdit) {
            setMotion({
                action_id: acao.id,
                isPublic: processo.isPublic,
                number: processo.number,
                caseTitle: processo.caseTitle,
                caseDescription: processo.caseDescription,
                caseType: processo.caseType,
                prosecutor: processo.prosecutor,
                defendant: processo.defendant,
                status: processo.status,
                courtSection: processo.courtSection,
                court_id: processo.court_id,
                judge: processo.judge,
                notes: processo.notes,
                filingDate: processo.filingDate
            })
            if (acao.auth === 1) {
                setAuthorization(processo.authorization)
            }
        } else {
            setError({
                number: false,
                caseTitle: false,
                caseDescription: false,
                caseType: false,
                prosecutor: false,
                defendant: false,
                status: false,
                court_id: false,
                courtSection: false,
                judge: false,
                notes: false,
                filingDate: false,
                isPublic: false
            })
            setMotion({
                action_id: acao.id,
                isPublic: '',
                number: '',
                caseTitle: '',
                caseDescription: '',
                caseType: '',
                prosecutor: '',
                defendant: '',
                status: '',
                court_id: '',
                courtSection: '',
                judge: '',
                notes: '',
                filingDate: ''
            })
        }

    }, [])

    useEffect(() => {
        let errorProcess = false;
        for (const item of Object.keys(motion)) {
            if (item === 'file') continue;
            if ((motion[item] === '' || motion[item] === ' ' || motion[item] === undefined) && (motion[item] !== false && motion[item] !== true)) {
                errorProcess = true
            }
        }
        if (acao.auth === 1) {
            if (Object.keys(authorization).length === 0) {
                errorProcess = true
            }
        }
        if (errorProcess) {
            setCanInsert(true)
        } else {
            setCanInsert(false)
        }
    }, [motion])

    useEffect(() => {
        if (varas.length > 0 && states.offsetJuriVaras > 0) {
            getVaraForm(motion.court_id)
        }
    }, [states.offsetJuriVaras])


    // useEffect(() => {
    //     if (motion.courtSection === 'more') {
    //         moreVaras()
    //         setMotion({ ...motion, courtSection: '' })
    //     }
    // }, [motion.courtSection])

    // useEffect(() => {
    //     if (motion.court_id === 'more') {
    //         moreTribunais()
    //         setMotion({ ...motion, court_id: '', courtSection: "" })
    //     }
    // }, [motion.court_id])

    return (
        <div className="flex w-full flex-col  p-3">
            {/* Adicao de arquivo e text */}
            <div className="flex flex-col w-full">
                <p className="text-sm font-bold text-gray-500">Adicionar {acao.type === 2 ? "processo" : "processos"}</p>
            </div>

            <div className="flex flex-col w-full mt-4 rounded-[10px] border p-3">

                {/* Primeira Parte do formulário */}
                <div className="flex flex-col sm:flex-row">
                    <div className="flex flex-col w-full sm:w-[33%]">
                        <p className="text-sm text-gray-700">Número</p>
                        <input
                            type="text"
                            value={motion.number}
                            onChange={(e) => setMotion({ ...motion, number: e.target.value })}
                            className={`border rounded-[10px] p-2 mt-2 ${error.number ? 'border-red-500' : 'border-gray-300'}`}
                        />
                        {
                            error.number && <p className="text-sm font-bold text-red-500">Adicione um número para o processo</p>
                        }
                    </div>
                    <div className="flex flex-col  w-full sm:w-[33%] mt-3 sm:mt-0 sm:ml-2">
                        <p className="text-sm text-gray-700">Título do processo</p>
                        <input
                            type="text"
                            value={motion.caseTitle}
                            onChange={(e) => setMotion({ ...motion, caseTitle: e.target.value })}
                            className={`border rounded-[10px] p-2 mt-2 ${error.caseTitle ? 'border-red-500' : 'border-gray-300'}`}
                        />
                        {
                            error.caseTitle && <p className="text-sm font-bold text-red-500">Adicione um título para o processo</p>
                        }
                    </div>
                    <div className="flex flex-col w-full sm:w-[33%] mt-3 sm:mt-0 sm:ml-2">
                        <p className="text-sm text-gray-700">Tipo do processo</p>
                        <input
                            type="text"
                            value={motion.caseType}
                            onChange={(e) => setMotion({ ...motion, caseType: e.target.value })}
                            className={`border rounded-[10px] p-2 mt-2 ${error.caseType ? 'border-red-500' : 'border-gray-300'}`}
                        />
                        {
                            error.caseType && <p className="text-sm font-bold text-red-500">Adicione um tipo para o processo</p>
                        }
                    </div>
                </div>

                {/* Segunda Parte do formulário */}

                <div className="flex flex-col sm:flex-row">
                    <div className="flex flex-col w-full sm:w-[33%]  mt-3 ">
                        <p className="text-sm text-gray-700">Sujeito Ativo</p>
                        <input
                            type="text"
                            value={motion.prosecutor}
                            onChange={(e) => setMotion({ ...motion, prosecutor: e.target.value })}
                            className={`border rounded-[10px] p-2 mt-2 ${error.prosecutor ? 'border-red-500' : 'border-gray-300'}`}
                        />
                        {
                            error.prosecutor && <p className="text-sm font-bold text-red-500">Adicione um sujeito ativo para o processo</p>
                        }
                    </div>
                    <div className="flex flex-col w-full sm:w-[33%]  mt-3 sm:ml-2">
                        <p className="text-sm text-gray-700">Sujeito Passivo</p>
                        <input
                            type="text"
                            value={motion.defendant}
                            onChange={(e) => setMotion({ ...motion, defendant: e.target.value })}
                            className={`border rounded-[10px] p-2 mt-2 ${error.defendant ? 'border-red-500' : 'border-gray-300'}`}
                        />
                        {
                            error.defendant && <p className="text-sm font-bold text-red-500">Adicione um sujeito passivo para o processo</p>
                        }
                    </div>
                    <div className="flex flex-col w-full sm:w-[33%]  mt-3 sm:ml-2">
                        <p className="text-sm text-gray-700">Juiz</p>
                        <input
                            type="text"
                            value={motion.judge}
                            onChange={(e) => setMotion({ ...motion, judge: e.target.value })}
                            className={`border rounded-[10px] p-2 mt-2 ${error.judge ? 'border-red-500' : 'border-gray-300'}`}
                        />
                        {
                            error.judge && <p className="text-sm font-bold text-red-500">Adicione um juiz para o processo</p>
                        }
                    </div>
                </div>

                {/* Terceira Parte do formulário */}

                <div className="flex flex-col sm:flex-row">
                    <div className="flex flex-col w-full sm:w-[50%]  mt-3 sm:ml-2">
                        <p className="text-sm text-gray-700">Status</p>
                        <select
                            value={motion.status}
                            onChange={(e) => setMotion({ ...motion, status: e.target.value })}
                            className={`border rounded-[10px] p-2 mt-2 ${error.status ? 'border-red-500' : 'border-gray-300'}`}
                        >
                            <option value={null}>Selecione</option>
                            <option value="1">Em andamento</option>
                            <option value="2">Encerrado</option>
                        </select>
                        {
                            error.status && <p className="text-sm font-bold text-red-500">Adicione um status para o processo</p>
                        }
                    </div>
                    <div className="flex flex-col w-full sm:w-[50%]  mt-3 sm:ml-2">
                        <p className="text-sm text-gray-700">Tribunal</p>
                        <select
                            value={motion.court_id}
                            onChange={(e) => changeTribunal(e.target.value)}
                            className={`border rounded-[10px] p-2 mt-2 ${error.courtSection ? 'border-red-500' : 'border-gray-300'}`}
                        >
                            <option value=''>Selecione</option>
                            {
                                tribunais.map((vara, index) => (
                                    <option key={index} value={vara.id}>{vara.name}</option>
                                ))
                            }
                            {
                                !states.limitSearchJuriTribunais &&
                                <option value='more'>Carregar mais</option>
                            }
                        </select>
                        {
                            error.court_id && <p className="text-sm font-bold text-red-500">Adicione um tribunal para o processo</p>
                        }
                    </div>
                    {
                        (motion.court_id !== '' && varas.length > 0) &&
                        <div className="flex flex-col w-full sm:w-[50%]  mt-3 sm:ml-2">
                            <p className="text-sm text-gray-700">Vara</p>
                            <select
                                value={motion.courtSection}
                                onChange={(e) => changeVara(e.target.value)}
                                className={`border rounded-[10px] p-2 mt-2 ${error.courtSection ? 'border-red-500' : 'border-gray-300'}`}
                            >
                                <option value=''>Selecione</option>
                                {
                                    varas.map((vara, index) => (
                                        <option key={index} value={vara.id}>{vara.name}</option>
                                    ))
                                }
                                {
                                    !states.limitSearchJuriVaras &&
                                    <option value='more'>Carregar mais</option>
                                }
                            </select>
                            {
                                error.courtSection && <p className="text-sm font-bold text-red-500">Adicione uma vara para o processo</p>
                            }
                        </div>
                    }
                </div>

                {/* Quarta Parte do formulário */}
                <div className="flex flex-col sm:flex-row">
                    <div className="flex flex-col sm:w-[50%]  mt-3 sm:ml-2">
                        <p className="text-sm text-gray-700">Data de distribuição</p>
                        <input
                            type="date"
                            value={motion.filingDate}
                            onChange={(e) => setMotion({ ...motion, filingDate: e.target.value })}
                            className={`border rounded-[10px] p-2 mt-2 ${error.filingDate ? 'border-red-500' : 'border-gray-300'}`}
                        />
                        {
                            error.filingDate && <p className="text-sm font-bold text-red-500">Adicione uma data de distribuição para o processo</p>
                        }
                    </div>
                    <div className="flex flex-col sm:w-[50%]  mt-3 sm:ml-2">
                        <p className="text-sm text-gray-700">Público</p>
                        <select
                            value={motion.isPublic}
                            onChange={(e) => setMotion({ ...motion, isPublic: e.target.value })}
                            className={`border rounded-[10px] p-2 mt-2 ${error.publica ? 'border-red-500' : 'border-gray-300'}`}
                        >
                            <option value={null}>Selecione</option>
                            <option value={true}>Sim</option>
                            <option value={false}>Não</option>
                        </select>
                        {
                            error.isPublic && <p className="text-sm font-bold text-red-500">Selecione se o processo é público ou não</p>
                        }
                    </div>
                </div>


                <div className="flex flex-col w-full mt-3">
                    <p className="text-sm text-gray-700">Descrição do processo</p>
                    <textarea
                        value={motion.caseDescription}
                        onChange={(e) => setMotion({ ...motion, caseDescription: e.target.value })}
                        className={`border rounded-[10px] p-2 mt-2 ${error.caseDescription ? 'border-red-500' : 'border-gray-300'}`}
                    />
                    {
                        error.caseDescription && <p className="text-sm font-bold text-red-500">Adicione uma descrição para o processo</p>
                    }
                </div>
                <div className="flex flex-col w-full mt-3">
                    <p className="text-sm text-gray-700">Observações</p>
                    <textarea
                        value={motion.notes}
                        onChange={(e) => setMotion({ ...motion, notes: e.target.value })}
                        className={`border rounded-[10px] p-2 mt-2 ${error.notes ? 'border-red-500' : 'border-gray-300'}`}
                    />
                </div>
                {
                    acao.auth === 1 &&
                    <div className="flex flex-col w-full">
                        <div className="flex flex-col w-full items-between justify-between p-4">
                            <div className="flex flex-row w-full items-between justify-between">
                                <p className="font-bold mb-2">Autorização de inclusão do processo</p>
                            </div>
                            <div>
                                {
                                    !openAuthorization && (<p className={Object.keys(authorization).length === 0 ? "font-classic text-red-500" : "font-classic text-green-500"}>
                                        {
                                            Object.keys(authorization).length == 0 ? 'Nenhuma autorização cadastrada, obrigatório pelo menos uma autorização' : Object.keys(authorization).length > 0 && (

                                                <div className="flex flex-col">
                                                    <div className="grid grid-cols-1 gap-4 mt-4">
                                                        <div className="flex flex-col w-full items-between justify-between p-4 border rounded-[10px]">
                                                            <div className="flex flex-row w-full items-between justify-between">
                                                                <XMarkIcon className="h-5 w-5 text-red-500" onClick={() => deleteAutorizacao()} />
                                                                <PencilIcon className="h-5 w-5 text-green-500" onClick={() => updateAuthorization(authorization)} />
                                                            </div>
                                                            <span className="font-bold mt-2" />
                                                            <div className="flex flex-row w-full items-between justify-between">
                                                                <p className="font-bold mb-2">Seção: {truncateText(authorization.title, 15)}</p>
                                                            </div>
                                                            <div className="flex flex-row w-full items-between justify-between">
                                                                <p className="font-bold mb-2 ">Descrição: {truncateText(authorization.description, 15)}</p>
                                                            </div>
                                                            <div className="flex flex-row w-full items-between justify-between">
                                                                <p className="font-bold mb-2">E-mail de envio: {authorization.email}</p>
                                                            </div>
                                                            <div className="flex flex-row w-full items-between justify-between">
                                                                <p className="font-bold mb-2">Arquivo: {authorization.file.name}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            )

                                        }
                                    </p>)
                                }
                            </div>
                        </div>
                        {
                            (Object.keys(authorization).length === 0 || isEditAuthorization === true) ?
                                <AdicaoAutorizacao states={states} setters={setters} adicao={e => addAutorizacao(e)} isEdit={isEditAuthorization} authorization={authorization} edit={editAutorizacao} />
                                : <></>
                        }
                    </div>
                }

                {/* {
                    canInsert && */}
                <div className="flex w-full mt-3">
                    <button
                        className="w-full bg-blue-500 text-white rounded-[10px] p-2"
                        onClick={() => saveMotion()}

                    >
                        {
                            isEdit ? 'Editar' : 'Adicionar'
                        }
                    </button>
                </div>
                {/* } */}
            </div>
        </div >
    )
}


export default AdicaoProcesso