import NavBar from "../../../Components/Navbar/navbar";
import NavBarPanel from "../../../Components/navMenu/juridico";
import SpinnerOverlay from "../../../Components/SpinnerOverlay";
import { useNavigate } from "react-router-dom";
import { InformationCircleIcon } from '@heroicons/react/20/solid'
import { AuthContext } from "../../../Context";
import { AuthContextJuri } from "../../../Context/juri";
import { AuthContextApi } from "../../../Context/api";
import { useContext, useEffect, useState } from "react";
import Cookies from 'js-cookie'
import ModalAcao from "../../../Components/Modal/Acao";
import ModalConfirm from "../../../Components/Modal/Confirm";
import ModalShowAcoes from "../../../Components/Modal/ShowAcao";
import Footer from "../../../Components/Footer/footer";

import Table from '../../../Components/Tabela/TabelaAcoesJuridico'

const AcoesJuridico = () => {

    const { states, setters } = useContext(AuthContext);
    const {
        checkToken,
        dataSystemAcoes,
        dataAcoes,
        dataAcoesQuery,
        createAcao,
        updateAcao,
        deleteAcao,
        dataAcaoById,
        dataEscritorioForm,
        dataTribunalForm,
        dataLawyerForm
    } = useContext(AuthContextJuri);
    const { dataAllFundo } = useContext(AuthContextApi)


    const [title, setTitle] = useState('')
    const [description, setDescription] = useState('')
    const [search, setSearch] = useState('')


    const hist = useNavigate()
    const checkTokenLoad = async () => {
        const cookie = Cookies.get('tokenJuri')
        if (cookie) {
            await checkToken()
        } else {
            setters.setIsSignedJuri(false)
            setters.setInsertCode(false)
            hist('/perfil')
        }
    }

    const loadData = async () => {
        await dataSystemAcoes()
        await dataAcoes()
    }

    const getActionSearchQuery = async () => {
        if (search === '') {
            loadData()
        } else {
            await dataAcoesQuery(search)
        }
    }

    const loadDataControl = async () => {
        if (search === '') {
            loadData()
        } else {
            await dataAcoesQuery(search)
        }
    }


    //Tem que mudar
    const headers = ['', 'Nome da ação', 'Status de Cadastro', 'Escritório | Advogado', 'Tipo da Ação', 'Ação interna', 'Restrita por EFPC', 'Vinculação Automática', 'E-mail criador', 'Data de criação', 'Data de atualização']

    const next = async () => {
        if (states.limitSearchJuriAcoes && states.offsetJuriAcoes > 0) {
            return;
        } else {
            setters.setOffsetJuriAcoes(states.offsetJuriAcoes + states.limitJuriAcoes)
        }
    };

    const previous = async () => {
        if (states.limitSearchJuriAcoes && states.offsetJuriAcoes <= 0) {
            states.offsetJuriAcoes < 0 && setters.setOffsetJuriAcoes(0)
            return;
        } else {
            setters.setOffsetJuriAcoes(states.offsetJuriAcoes - states.limitJuriAcoes)
        }
    };

    const addProcess = async () => {
        await dataAllFundo()
        const escritorio = await dataEscritorioForm()
        const lawyer = await dataLawyerForm()
        if (!escritorio || !lawyer) return;
        setters.setModalJuri(true)
        setters.setIsEditJuri(false)
    }



    const edit = async (data) => {
        setters.setIsEditJuri(true)
        await dataAllFundo()
        const escritorio = await dataEscritorioForm()
        const lawyer = await dataLawyerForm()
        if (!escritorio || !lawyer) return;
        const resp = await dataAcaoById(data.id)
        if (resp === false) {
            return;
        }
        setters.setModalJuri(true)
    }



    const actionModal = async (data) => {
        if (states.isEditJuri) {
            const id = data.id
            delete data.id
            await updateAcao(data, id)
            setters.setIsEditJuri(false)
        } else {
            const resp = await createAcao(data)
            if (resp.status) {
                hist(`/juridicoIndex/acoes/completarCadastro/${resp.data}`)
            }
        }
    }




    const deleteModal = async (data) => {
        setters.setConfirmModal(true)
        setTitle(`Deletar ação ${data.name}`)
        setDescription(`Ao confirmar a ação ${data.name} será deletada do sistema.`)
        setters.setJuriAcoes(data)
    }

    const deleteAcaoAction = async () => {
        await deleteAcao(states.JuriAcoes.id)
    }



    const showAcao = async (data) => {
        const resp = await dataAcaoById(data.id)
        if (resp === false) {
            return;
        }
        setters.setModalAcoes(true)
    }

    const loadEscritorio = async () => {
        await dataEscritorioForm()
    }

    const moreEscritorio = async () => {
        setters.setOffsetJuriEscritorio(states.offsetJuriEscritorio + states.limitJuriEscritorio)
    }



    const actionComplete = async data => {
        hist(`/juridicoIndex/acoes/completarCadastro/${data.id}`)
    }

    const paginaDeControleAcao = data => {
        hist(`/juridicoIndex/acoes/acao/${data.id}`)
    }

    const loadLawyer = async () => {
        await dataLawyerForm()
    }

    const moreLoadLawyer = async () => {
        setters.setOffsetJuriLawyer(states.offsetJuriLawyer + states.limitJuriLawyer)
    }


    useEffect(() => {
        if (states.dataJuriAcoes?.length > 0) loadDataControl()
    }, [states.offsetJuriAcoes])



    useEffect(() => {
        if (states.dataJuriEscritorio?.length > 0) loadEscritorio()
    }, [states.offsetJuriEscritorio])



    useEffect(() => {
        if (states.dataJuriLawyer?.length > 0) loadLawyer()
    }, [states.offsetJuriLawyer])





    useEffect(() => {
        if (states.dataJuriAcoes?.length === 0) loadData()
        setters.setOffsetJuriAcoes(0)
        setters.setModalJuri(false)

        checkTokenLoad()

        return () => {
            setters.setIsEditJuri(false)
            setters.setDataJuriAcoes([])
            setters.setDataJuriLawyer([])
            setters.setJuriAcoes({})
            setters.setOffsetJuriAcoes(0)
            setters.setOffsetJuriEscritorio(0)
            setters.setOffsetJuriLawyer(0)
            setters.setOffsetJuriTribunais(0)
            setters.setLimitSearchJuriAcoes(false)
        }
    }, [])


    return (
        <>
            <div className="flex flex-1 flex-col pb-16 bg-white">
                <NavBar />
                <SpinnerOverlay />
                <div className='flex flex-1 flex-col md:pl-64 mb-4 mt-2'>
                    <div className="p-4">
                        <div className="px-4 sm:px-6 lg:px-8">
                            <NavBarPanel />
                            <h1 className="text-base font-semibold leading-6 text-gray-900">Ações</h1>
                            <p className="mt-2 text-sm text-gray-700">
                                Informações sobre as ações cadastrados.
                            </p>
                            <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-4 mt-4">
                                {states.dataSystemJuriAcoes?.map((card, index) => {
                                    return (
                                        <div key={card.title} className={card.value > 0 && index === -1 ? "border border-1 border-gray-200 rounded-md overflow-hidden bg-white hover:shadow-md" : "border border-1 border-gray-200 rounded-md overflow-hidden bg-white hover:shadow-md"}>
                                            <div className="p-5">
                                                <div className="flex items-center">
                                                    <div className="flex-shrink-0">
                                                        {
                                                            card.value > 0 && index === -1 ? (<InformationCircleIcon className="w-6 h-6 text-red-400" aria-hidden="true" />) : (<InformationCircleIcon className="w-6 h-6 text-gray-400" aria-hidden="true" />)
                                                        }
                                                    </div>
                                                    <div className="ml-5 w-0 flex-1">
                                                        <dl>
                                                            {
                                                                card.value > 0 && index === -1 ? (<dt className="truncate text-sm font-medium text-red-500">{card.title}</dt>) : (<dt className="truncate text-sm font-medium text-gray-500">{card.title}</dt>)
                                                            }
                                                            <dd>
                                                                {
                                                                    card.value > 0 && index === -1 ?
                                                                        (<div className="text-lg font-medium text-red-500">
                                                                            {card.value}
                                                                        </div>) : (
                                                                            <div className="text-lg font-medium text-gray-900">
                                                                                {card.value}
                                                                            </div>)
                                                                }
                                                            </dd>
                                                        </dl>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                            <div className="bg-white flex flex-col items-center justify-center bg-white border border-1 border-gray-200 rounded-md overflow-hidden bg-white p-4 mt-4">
                                <h1 className='text-xl font-semibold'>Busca por ações</h1>
                                <div className='flex lg:flex-row flex-col items-center justify-center mt-2 w-full'>
                                    <input placeholder='Digite o dado que deseja buscar' type="text" onChange={e => setSearch(e.target.value)}
                                        value={search} className="border border-gray-300 px-2 py-1 rounded focus:outline-none focus:border-blue-500  w-[35%]"
                                        onKeyDown={e => {
                                            if (e.key === 'Enter') {
                                                getActionSearchQuery()
                                            }
                                        }}
                                    />
                                    <div className="flex items-center md:ml-5 p-2">
                                        <button className="bg-blue-500 hover:bg-blue-600 text-white py-1 font-bold px-8 rounded" onClick={getActionSearchQuery}>
                                            Aplicar
                                        </button>
                                        {
                                            states.isLoading && (
                                                <div className="flex items-center justify-center ml-8">
                                                    <div className="h-8 w-8 animate-spin rounded-full border-t-2 border-b-2 border-gray-500"></div>
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                            <Table add={addProcess} show={showAcao} del={deleteModal} edit={edit} title='Ações cadastrados' complete={actionComplete} panel={paginaDeControleAcao}
                                description='Registro dos ações cadastrados no jurídico' next={next} previous={previous} states={states} headers={headers}
                                infoTable={{ offset: states.offsetJuriAcoes, limit: states.limitJuriAcoes }} dataTable={states.dataJuriAcoes} />
                            <ModalAcao action={actionModal} states={states} setters={setters} more={moreEscritorio}
                                advogadosMore={moreLoadLawyer} />
                            <ModalShowAcoes states={states} setters={setters} />
                            <ModalConfirm states={states} setters={setters} title={title} description={description}
                                action={deleteAcaoAction} />
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    )
}



export default AcoesJuridico;