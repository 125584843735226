import NavBar from "../../../../Components/Navbar/navbar";
import SpinnerOverlay from "../../../../Components/SpinnerOverlay";
import { useNavigate, useParams } from "react-router-dom";
import { AuthContext } from "../../../../Context";
import { AuthContextJuri } from "../../../../Context/juri";
import { AuthContextApi } from "../../../../Context/api";
import { ArrowUpCircleIcon, ArrowDownCircleIcon, PencilIcon, XMarkIcon, PlusIcon } from "@heroicons/react/24/outline";
import { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import Cookies from 'js-cookie'
import Footer from "../../../../Components/Footer/footer";
import ShowAcao from "../../../../Components/juridico/Acao";
import AdicaoSecao from "../../../../Components/juridico/adicaoSecao";
import AdicaoProcesso from "../../../../Components/juridico/adicaoProcesso";
import moment from "moment";


const AcoesJuridico = () => {

    const { states, setters } = useContext(AuthContext);
    const {
        checkToken,
        dataAcaoById,
        dataVaraForm,
        dataVaraAcaoCadastro,
        dataTribunalForm,
        completeAcao
    } = useContext(AuthContextJuri);



    const { id } = useParams()
    const hist = useNavigate()

    const [openInfoAcao, setOpenInfoAcao] = useState(false)
    const [openSections, setOpenSections] = useState(false)
    const [openInsertSection, setOpenInsertSection] = useState(false)
    const [isEditSection, setIsEditSection] = useState(false)
    const [sections, setSections] = useState([])
    const [section, setSection] = useState({})


    const [openProcessos, setOpenProcessos] = useState(false)
    const [openInsertProcesso, setOpenInsertProcesso] = useState(false)
    const [isEditProcesso, setIsEditProcesso] = useState(false)
    const [processos, setProcessos] = useState([])
    const [processo, setProcesso] = useState({})

    const addSecao = e => {
        sections.push(e)
        setOpenSections(false)
    }

    const editSecao = e => {
        setIsEditSection(true)
        setSection(e)
        setOpenSections(true)
    }

    const editSection = e => {
        const indexSection = sections.findIndex(e => e.id === section.id)
        sections[indexSection] = e
        setSection({})
        setOpenSections(false)
    }

    const deleteSection = e => {
        const newArray = sections.filter(sec => sec.id !== e.id)
        setSections(newArray)
    }

    const addProcesso = e => {
        processos.push(e)
        setOpenProcessos(false)
    }

    const editProcess = e => {
        setIsEditProcesso(true)
        setProcesso(e)
        setOpenProcessos(true)
    }

    const editProcesso = e => {
        const indexSection = processos.findIndex(e => e.id === processo.id)
        processos[indexSection] = e
        setProcesso({})
        setOpenProcessos(false)
    }

    const deleteProcesso = e => {
        const newArray = processos.filter(sec => sec.id !== e.id)
        setProcessos(newArray)
    }

    const truncateText = (text, maxLength) => {
        if (text?.length > maxLength) {
            return text?.substring(0, maxLength) + '...';
        }
        return text;
    };

    const firstLoad = async () => {
        const cookie = Cookies.get('tokenJuri')
        if (cookie) {
            const resp = await checkToken()
            if (!resp) hist('/juridicoIndex/acoes')
            const check = await dataAcaoById(id)
            if (!check) hist('/juridicoIndex/acoes')
            const checkTribunal = await dataTribunalForm()
            if (!checkTribunal) hist('/juridicoIndex/acoes')
        } else {
            setters.setIsSignedJuri(false)
            setters.setInsertCode(false)
            hist('/perfil')
        }
    }

    // const varasLoad = async () => {
    //     const checkVara = await dataVaraAcaoCadastro(states.JuriAcoes.court_id)
    //     if (!checkVara) {
    //         toast.warning(`Não foi possível carregar as varas do tribunal. Cadastre as varas para continuar`)
    //         hist('/juridicoIndex/acoes')
    //     }
    // }

    // const loadVarasLoad = async () => {
    //     const checkVara = await dataVaraForm(states.JuriAcoes.court_id)
    //     if (!checkVara) toast.warning(`Limite de varas atingido, não é possível carregar mais varas`)
    // }

    const concludeCadastro = async () => {
        if (sections.length === 0 || processos.length === 0) {
            toast.warning('Adicione pelo menos uma seção e um processo para concluir o cadastro')
            return
        }
        const formData = new FormData()
        const processOutId = processos.map(e => {
            return {
                ...e, id: null, action_id: states.JuriAcoes.id,
                authorization: Object.keys(e.authorization).length > 0 ? { ...e.authorization, id: null, file: null } : null
            }
        })
        if (states.JuriAcoes.auth === 1) {
            for (const item of processos) {
                if (item.authorization) {
                    formData.append('fileAuthorization', item.authorization.file)
                }
            }
        }
        formData.append('processos', JSON.stringify(processOutId))
        const sectionsOutFileId = sections.map(e => {
            return {
                ...e, id: null, action_id: states.JuriAcoes.id, haveFile: e.file ? true : false
            }
        })
        sections.forEach(e => {
            if (e.file) {
                formData.append('fileSection', e.file)
            }
        })
        formData.append('secoes', JSON.stringify(sectionsOutFileId))

        const resp = await completeAcao(formData, states.JuriAcoes.id)
        if (resp) {
            hist('/juridicoIndex/acoes')
        }


    }

    const getVarasWhenTribunal = async (tribunal) => {
        const checkVara = await dataVaraAcaoCadastro(tribunal, 0)
        if (!checkVara) {
            toast.warning(`Não foi possível carregar as varas do tribunal. Troque o tribunal para continuar`)
            return false
        }
        return true
    }

    const getVarasWhenTribunalForm = async (tribunal) => {
        const checkVara = await dataVaraForm(tribunal)
        if (!checkVara) {
            toast.warning(`Não foi possível carregar as varas do tribunal. Troque o tribunal para continuar`)
        }
    }


    useEffect(() => {
        firstLoad()

        return () => {
            setters.setJuriAcoes({})
            setters.setOffsetJuriVaras(0)
            setters.setDataJuriVaras([])
            setters.setJuriVaras({})
            setters.setLimitSearchJuriVaras(false)
            setters.setLimitSearchJuriTribunais(false)

        }
    }, [])

    useEffect(() => {
        if (states.dataJuriTribunais.length > 0) {
            dataTribunalForm();
        }
    }, [states.offsetJuriTribunais])




    useEffect(() => {
        if (Object.keys(states.JuriAcoes).length > 0) {
            if (states.JuriAcoes.isComplete) {
                toast.warning('Ação já está completa')
                hist('/juridicoIndex/acoes')
            }
        }
    }, [states.JuriAcoes])


    return (
        <>
            <div className="flex flex-1 flex-col pb-16 bg-white">
                <NavBar />
                <SpinnerOverlay />
                <div className='flex flex-1 flex-col md:pl-64 mb-4 mt-2'>
                    <div className="p-4">
                        <div className="px-4 sm:px-6 lg:px-8">
                            <div className="w-full flex justify-between">
                                <button onClick={() => hist('/juridicoIndex/acoes')} className="flex items-center border p-2 rounded-[10px] hover:shadow  hover:bg-blue-400  hover:text-white text-gray-700 ">
                                    <p className="text-sm  mt-2">Voltar</p>
                                </button>
                                <button onClick={concludeCadastro} className="flex items-center border p-2 rounded-[10px] hover:shadow  hover:bg-blue-400  hover:text-white text-gray-700 ">
                                    <p className="text-sm  mt-2">Concluir Cadastro</p>
                                </button>
                            </div>
                            <div className="w-full mt-4">
                                <h1 className="text-base font-semibold leading-6 text-gray-900">Ação - {states.JuriAcoes.name}</h1>
                                <p className="mt-2 text-sm text-gray-700">
                                    Completar Cadastro de ação, adicionando os objetos da ação <br /> e os processos que estão relacionados a ela.
                                </p>
                            </div>
                            <ShowAcao acao={states.JuriAcoes} />

                            {/* PAINEL para cadastramento de seções */}
                            <div className="flex flex-col w-full p-4 sm:px-6 lg:px-8 mt-4 border rounded-[10px] flex justify-between items-center">
                                <div className="flex flex-col w-full items-between justify-between p-4">
                                    <div className="flex flex-row w-full items-between justify-between">
                                        <p className="font-bold mb-2">Objeto da ação</p>
                                        {
                                            sections.length > 0 && (
                                                <button onClick={() => {
                                                    setSection({})
                                                    setOpenInsertSection(!openInsertSection)
                                                }} className="flex flex-row text-center items-center justify-center bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full">
                                                    {
                                                        openInsertSection ? <ArrowUpCircleIcon className="h-10 w-6 " /> : <ArrowDownCircleIcon className="h-5 w-5 " />
                                                    }
                                                </button>
                                            )
                                        }
                                        <button onClick={() => {
                                            setOpenSections(!openSections)
                                            setIsEditSection(false)
                                        }} className="flex flex-row text-center items-center justify-center bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full">
                                            {
                                                openSections ? <XMarkIcon className="h-10 w-6 " /> : <PlusIcon className="h-5 w-5 " />
                                            }
                                        </button>
                                    </div>
                                    <div>
                                        {
                                            !openSections && (<p className={sections.length === 0 ? "font-classic text-red-500" : "font-classic text-green-500"}>
                                                {
                                                    sections.length === 0 ? 'Nenhum objeto cadastrada, obrigatório pelo menos um objeto' : sections.length > 0 ? !openInsertSection && (

                                                        <div className="flex flex-col">
                                                            <div className="grid sm:grid-cols-3 grid-cols-1  gap-4 mt-4">
                                                                {
                                                                    sections.map(e => {
                                                                        return (
                                                                            <div className="flex flex-col w-full items-between justify-between p-4 border rounded-[10px]">
                                                                                <div className="flex flex-row w-full items-between justify-between">
                                                                                    <PencilIcon className="h-5 w-5 text-blue-500" onClick={() => editSecao(e)} />
                                                                                    <XMarkIcon className="h-5 w-5 text-red-500" onClick={() => deleteSection(e)} />
                                                                                </div>
                                                                                <span className="font-bold mt-2" />
                                                                                <div className="flex flex-row w-full items-between justify-between">
                                                                                    <p className="font-bold mb-2" style={{ wordBreak: 'break-word', overflowWrap: 'break-word' }}>Objeto: {truncateText(e.title, 15)}</p>
                                                                                </div>
                                                                                <div className="flex flex-row w-full items-between justify-between">
                                                                                    <p className="font-bold mb-2 " style={{ wordBreak: 'break-word', overflowWrap: 'break-word' }}>Texto: {truncateText(e.text, 15)}</p>
                                                                                </div>
                                                                                <div className="flex flex-row w-full items-between justify-between">
                                                                                    <p className="font-bold mb-2" style={{ wordBreak: 'break-word', overflowWrap: 'break-word' }}>Pública: {e.publica ? 'Sim' : 'Não'}</p>
                                                                                </div>
                                                                                {
                                                                                    e.file && (
                                                                                        <div className="flex flex-row w-full items-between justify-between">
                                                                                            <p className="font-bold mb-2">Arquivo: {truncateText(e.file.name, 15)}</p>
                                                                                        </div>
                                                                                    )
                                                                                }
                                                                            </div>
                                                                        )
                                                                    })
                                                                }
                                                            </div>
                                                        </div>

                                                    ) : (<p className="font-classic text-red-500">Clique no botão de + para adicionar mais um objeto</p>)

                                                }
                                            </p>)
                                        }
                                    </div>
                                </div>
                                {
                                    // area de criacao e vizualizacao das secoes
                                    openSections && (
                                        <>
                                            <AdicaoSecao states={states} setters={setters} adicao={e => addSecao(e)} isEdit={isEditSection} section={section} edit={editSection} delete={deleteSection} sections={sections} />
                                        </>
                                    )
                                }
                            </div>

                            {/* PAINEL para cadastramento do processo */}
                            <div className="flex flex-col p-4 sm:px-6 w-full lg:px-8 mt-4 border rounded-[10px] flex justify-between items-center">
                                <div className="flex flex-col w-full items-between justify-between p-4">
                                    <div className="flex flex-row w-full items-between justify-between">
                                        <p className="font-bold mb-2">Processos da ação</p>
                                        {
                                            processos.length > 0 && (
                                                <button onClick={() => {
                                                    setProcesso({})
                                                    setOpenInsertProcesso(!openInsertProcesso)
                                                }} className="flex flex-row text-center items-center justify-center bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full">
                                                    {
                                                        openInsertProcesso ? <ArrowUpCircleIcon className="h-10 w-6 " /> : <ArrowDownCircleIcon className="h-5 w-5 " />
                                                    }
                                                </button>
                                            )
                                        }
                                        {
                                            (processos.length === 0 || states?.JuriAcoes.type !== 2) &&
                                            <button onClick={() => {
                                                setOpenProcessos(!openProcessos)
                                                setIsEditProcesso(false)
                                            }} className="flex flex-row text-center items-center justify-center bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full">
                                                {
                                                    openProcessos ? <XMarkIcon className="h-10 w-6 " /> : <PlusIcon className="h-5 w-5 " />
                                                }
                                            </button>
                                        }
                                    </div>
                                    <div>
                                        {
                                            !openProcessos && (<p className={processos.length === 0 ? "font-classic text-red-500" : "font-classic text-green-500"}>
                                                {
                                                    processos.length === 0 ? 'Nenhum processo cadastrado para essa ação.' : processos.length > 0 ? !openInsertProcesso && (

                                                        <div className="flex flex-col">
                                                            <div className="grid grid-cols-1  gap-4 mt-4">
                                                                {
                                                                    processos.map(e => {
                                                                        return (
                                                                            <div className="flex flex-col w-full items-between justify-between p-4 border rounded-[10px]">
                                                                                <div className="flex flex-row w-full items-between justify-between">
                                                                                    <PencilIcon className="h-5 w-5 text-blue-500" onClick={() => editProcess(e)} />
                                                                                    <XMarkIcon className="h-5 w-5 text-red-500" onClick={() => deleteProcesso(e)} />
                                                                                </div>
                                                                                <span className="font-bold mt-2" />
                                                                                <div className="flex flex-col w-full items-between justify-between">
                                                                                    <div className="flex flex-col sm:flex-row justify-between  p-1 mt-3">
                                                                                        <p className="font-bold mb-2" style={{ wordBreak: 'break-word', overflowWrap: 'break-word' }}>Número: {e.number}</p>
                                                                                        <p className="font-bold mb-2" style={{ wordBreak: 'break-word', overflowWrap: 'break-word' }}>Título: {truncateText(e.caseTitle, 10)}</p>
                                                                                        <p className="font-bold mb-2" style={{ wordBreak: 'break-word', overflowWrap: 'break-word' }}>Descrição: {truncateText(e.caseDescription, 10)}</p>
                                                                                        <p className="font-bold mb-2" style={{ wordBreak: 'break-word', overflowWrap: 'break-word' }}>Tipo: {e.caseType}</p>
                                                                                    </div>

                                                                                    <div className="flex flex-col sm:flex-row justify-between  p-1 mt-3">
                                                                                        <p className="font-bold mb-2" style={{ wordBreak: 'break-word', overflowWrap: 'break-word' }}>Sujeito Ativo: {e.prosecutor}</p>
                                                                                        <p className="font-bold mb-2" style={{ wordBreak: 'break-word', overflowWrap: 'break-word' }}>Status: {e.status}</p>
                                                                                        <p className="font-bold mb-2" style={{ wordBreak: 'break-word', overflowWrap: 'break-word' }}>Sujeito Passivo: {e.defendant}</p>
                                                                                    </div>
                                                                                    <div className="flex flex-col sm:flex-row justify-between  p-1 mt-3">
                                                                                        <p className="font-bold mb-2" style={{ wordBreak: 'break-word', overflowWrap: 'break-word' }}>Vara: {e.courtSectionName}</p>
                                                                                        <p className="font-bold mb-2" style={{ wordBreak: 'break-word', overflowWrap: 'break-word' }}>Juiz: {e.judge}</p>
                                                                                        <p className="font-bold mb-2" style={{ wordBreak: 'break-word', overflowWrap: 'break-word' }}>Notas: {e.notes}</p>
                                                                                        <p className="font-bold mb-2" style={{ wordBreak: 'break-word', overflowWrap: 'break-word' }}>Data de início: {moment(e.filingDate).format('DD/MM/YYYY')}</p>
                                                                                    </div>
                                                                                    {
                                                                                        e.authorization && (
                                                                                            <div className="flex flex-row w-full items-between justify-between mt-3">
                                                                                                <p className="font-bold mb-2">Arquivo: {truncateText(e.authorization?.file?.name, 15)}</p>
                                                                                            </div>
                                                                                        )
                                                                                    }
                                                                                </div>


                                                                            </div>
                                                                        )
                                                                    })
                                                                }
                                                            </div>
                                                        </div>

                                                    ) : (<p className="font-classic text-red-500">Clique no botão de + para adicionar um processo</p>)

                                                }
                                            </p>)
                                        }
                                    </div>
                                </div>
                                {
                                    // area de criacao e vizualizacao das secoes
                                    openProcessos && (
                                        <>
                                            <AdicaoProcesso
                                                states={states} setters={setters} adicao={e => addProcesso(e)} isEdit={isEditProcesso}
                                                truncateText={truncateText} getVara={getVarasWhenTribunal} getVaraForm={getVarasWhenTribunalForm}
                                                processo={processo} edit={editProcesso} delete={deleteProcesso}
                                                processos={processos} acao={states.JuriAcoes} varas={states.dataJuriVaras} tribunais={states.dataJuriTribunais} />
                                        </>
                                    )
                                }
                            </div>


                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    )
}



export default AcoesJuridico;