
import NavBar from "../../../Components/Navbar/navbar";
import NavBarPanel from "../../../Components/navMenu/juridico";
import SpinnerOverlay from "../../../Components/SpinnerOverlay";
import { useNavigate } from "react-router-dom";
import { InformationCircleIcon } from '@heroicons/react/20/solid'
import { AuthContext } from "../../../Context";
import { AuthContextJuri } from "../../../Context/juri";
import { useContext, useEffect, useState } from "react";
import Cookies from 'js-cookie'
import ModalConfirm from '../../../Components/Modal/Confirm'
import Footer from "../../../Components/Footer/footer";
//Tem que mudar 
import Table from '../../../Components/Tabela/TabelaAssociadoProcessoJuridico'
import ModalAdvogado from '../../../Components/Modal/TribunalJuri'


const AssociaddosPorAcao = () => {

    const { states, setters } = useContext(AuthContext);
    const {
        checkToken,
        dataSystemProcessosAssociados,
        dataAssociadoProcessos,
        dataAssociadoProcessosQuery,
        deleteRegisterTribunal

    } = useContext(AuthContextJuri);


    const [search, setSearch] = useState('')


    const hist = useNavigate()
    const checkTokenLoad = async () => {
        const cookie = Cookies.get('tokenJuri')
        if (cookie) {
            await checkToken()
        } else {
            setters.setIsSignedJuri(false)
            setters.setInsertCode(false)
            hist('/perfil')
        }
    }

    const loadData = async () => {
        setters.setModalJuri(false)
        await dataSystemProcessosAssociados()
        await dataAssociadoProcessos()
    }

    //NO DO USE EFFECT DEVE SER VERIFICADO CASO A DATA TENHA DADO JA PARA NAO CHAMAR DUAS VEZES


    const openModal = async () => {
        setters.setIsEditJuri(false)
        setters.setModalJuri(true)
    }


    const getLawyerSearch = async () => {
        if (search == '') {
            loadData()
        } else {
            setters.setLimitSearchJuriAssociadoProcesso(false)
            setters.setOffsetJuriAssociadoProcesso(0)
            setters.setDataJuriAssociadoProcesso([])
            await dataAssociadoProcessosQuery(search)
        }
    }


    //Tem que mudar
    const headers = ['Número do processo', 'Titulo Processo', 'Ação', 'Nome', 'Cpf', 'Fundo', 'Email', 'Tribunal', 'Advogado', 'Escritório', 'Data inicio processo', 'Próxima audiência', 'Última atualização', 'Data de cricação', 'Data de atualização']
    const [titleConfirm, setTitleConfirm] = useState('')
    const [descriptionConfirm, setDescriptionConfirm] = useState('')


    const actionModalConfirm = async () => {
        await deleteRegisterTribunal(states.JuriOutSourced.id)
        loadData()
    }


    const next = async () => {
        if (states.limitSearchJuriAssociadoProcesso && states.offsetJuriAssociadoProcesso > 0) {
            return;
        } else {
            setters.setOffsetJuriAssociadoProcesso(states.offsetJuriAssociadoProcesso + states.limitJuriAssociadoProcesso)
        }
    };

    const previous = async () => {
        if (states.limitSearchJuriAssociadoProcesso && states.offsetJuriAssociadoProcesso <= 0) {
            states.offsetJuriAssociadoProcesso < 0 && setters.setOffsetJuriAssociadoProcesso(0)
            return;
        } else {
            setters.setOffsetJuriAssociadoProcesso(states.offsetJuriAssociadoProcesso - states.limitJuriAssociadoProcesso)
        }
    };



    useEffect(() => {
        if (states.dataJuriAssociadoProcesso.length == 0) loadData()
        checkTokenLoad()


        return () => {
            setters.setDataJuriAssociadoProcesso([])
            setters.setJuriAssociadoProcesso({})
            setters.setOffsetJuriAssociadoProcesso(0)
            setters.setLimitSearchJuriAssociadoProcesso(false)

        }
    }, [])



    const formatCPF = (cpf) => {
        if (!cpf) {
            return '';
        }

        const cleanedCPF = cpf.replace(/\D/g, '');

        if (cleanedCPF.length !== 11) {
            return cpf; // Retorna o valor original se não for possível formatar
        }

        const cpfRegex = /^(\d{3})(\d{3})(\d{3})(\d{2})$/;
        const formattedCPF = cleanedCPF.replace(cpfRegex, '$1.$2.$3-$4');

        return formattedCPF;
    }


    useEffect(() => {
        if (states.dataJuriAssociadoProcesso.length > 0 && states.offsetJuriAssociadoProcesso > 0) {
            if (search == '') {
                dataAssociadoProcessos()
            } else {
                dataAssociadoProcessosQuery(search)
            }
        }
    }, [states.offsetJuriAssociadoProcesso])




    return (
        <>
            <div className="flex flex-1 flex-col pb-16 bg-white">
                <NavBar />
                <SpinnerOverlay />
                <div className='flex flex-1 flex-col md:pl-64 mb-4 mt-2'>
                    <div className="p-4">
                        <div className="px-4 sm:px-6 lg:px-8">
                            <NavBarPanel />
                            <h1 className="text-base font-semibold leading-6 text-gray-900">Associados Vinculados</h1>
                            <p className="mt-2 text-sm text-gray-700">
                                Informações sobre os associados que possuem vinculação a um processo jurídico
                            </p>
                            <div className="bg-white flex flex-col items-center justify-center bg-white border border-1 border-gray-200 rounded-md overflow-hidden bg-white p-4 mt-4">
                                <h1 className='text-xl font-semibold'>Busca por associados</h1>
                                <div className='flex lg:flex-row flex-col items-center justify-center mt-2 w-full'>
                                    <input placeholder='Digite o dado que deseja buscar' type="text" onChange={e => { setSearch(e.target.value) }}
                                        value={formatCPF(search)} className="border border-gray-300 px-2 py-1 rounded focus:outline-none focus:border-blue-500  w-[35%]"
                                        onKeyDown={e => {
                                            if (e.key === 'Enter') {
                                                getLawyerSearch()
                                            }
                                        }}
                                    />
                                    <div className="flex items-center md:ml-5 p-2">
                                        <button className="bg-blue-500 hover:bg-blue-600 text-white py-1 font-bold px-8 rounded" onClick={getLawyerSearch}>
                                            Aplicar
                                        </button>
                                        {
                                            states.isLoading && (
                                                <div className="flex items-center justify-center ml-8">
                                                    <div className="h-8 w-8 animate-spin rounded-full border-t-2 border-b-2 border-gray-500"></div>
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                            <Table add={openModal} title='Associados vinculados a um processo' description='Registro dos associados vincculados a processo' next={next} previous={previous} states={states} headers={headers} infoTable={{ offset: states.offsetJuriAssociadoProcesso, limit: states.limitJuriAssociadoProcesso }} dataTable={states.dataJuriAssociadoProcesso} />
                        </div>
                        <ModalConfirm states={states} action={actionModalConfirm} setters={setters} title={titleConfirm} description={descriptionConfirm} />
                        <ModalAdvogado states={states} setters={setters} />
                    </div>
                </div>
                <Footer />
            </div>
        </>
    )
}



export default AssociaddosPorAcao;