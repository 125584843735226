import { XMarkIcon, PencilIcon } from '@heroicons/react/24/outline'
import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useState, useEffect } from 'react'
import moment from 'moment'
import AdicaoAutorizacao from "../../juridico/adicaoAutorizacao"
import { toast } from 'react-toastify'

export default function ModalProcessoAcao({ states, setters, action, moreTribunal, vara, loadVara }) {




    const [form, setForm] = useState({
        isPublic: '',
        numero: '',
        titulo: '',
        status: '',
        tipo: '',
        reu: '',
        acusado: '',
        vara: '',
        court_id: '',
        juiz: '',
        dataAbertura: '',
        descricao: '',
        notas: '',
    })
    const [errorForm, setErrorForm] = useState({
        numero: false,
        acao: false,
        isPublic: false,
        titulo: false,
        status: false,
        tipo: false,
        reu: false,
        acusado: false,
        juiz: false,
        court_id: false,
        vara: false,
        dataAbertura: false,
        descricao: false,
        notas: false,
    })


    const [openAuthorization, setOpenAuthorization] = useState(false)
    const [authorization, setAuthorization] = useState({})
    const [isEditAuthorization, setIsEditAuthorization] = useState(false)
    const [dataSelectCourt, setDataSelectCourt] = useState([])

    const [dataSelectVara, setDataSelectVara] = useState([])

    const [needAuthorization, setNeedAuthorization] = useState(false)
    const [errorAuth, setErrorAuth] = useState(false)

    //Realizar a validação do arquivo aqui
    const onSubmitForm = async (e) => {
        e.preventDefault()

        let error = false;
        for (const item of Object.keys(form)) {
            if (form[item] == '' || form[item] == ' ' || form[item] == undefined) {
                error = true
                setErrorForm(e => {
                    return { ...e, [item]: true }
                })
            } else {
                setErrorForm(e => {
                    return { ...e, [item]: false }
                })
            }
        }


        // //console.log(states.JuriAcoes)
        if (states.JuriAcoes.auth === 1 && Object.keys(authorization).length === 0 && !states.isEditJuri) {
            setErrorAuth(true)
            error = true;
        } else {
            setErrorAuth(false)
        }

        if (error) {
            error = false
            return;
        }

        const formData = new FormData();
        formData.append('action_id', states.JuriAcoes.id)
        formData.append('caseTitle', form.titulo)
        formData.append('caseDescription', form.descricao)
        formData.append('caseType', form.tipo)
        formData.append('status', form.status)
        formData.append('courtSection', form.vara)
        formData.append('court_id', form.court_id)
        formData.append('judge', form.juiz)
        formData.append('isPublic', form.isPublic)
        formData.append('notes', form.notas)
        formData.append('filingDate', form.dataAbertura)
        formData.append('number', form.numero)
        formData.append('defendant', form.reu)
        formData.append('prosecutor', form.acusado)
        if (form.id) {
            formData.append('id', form.id)
        }
        if (states.JuriAcoes.auth === 1 && !states.isEditJuri) {
            formData.append('titleAuthorization', authorization.title)
            formData.append('descriptionAuthorization', authorization.description)
            formData.append('emailAuthorization', authorization.email)
            formData.append('fileAuthorization', authorization.file)
        }
        if (!states.isEditJuri) {
            await action(formData)
        } else {
            const obj = {
                action_id: states.JuriAcoes.id,
                caseTitle: form.titulo,
                caseDescription: form.descricao,
                caseType: form.tipo,
                status: form.status,
                court_id: form.court_id,
                courtSection: form.vara,
                judge: form.juiz,
                isPublic: form.isPublic,
                notes: form.notas,
                filingDate: form.dataAbertura,
                number: form.numero,
                defendant: form.reu,
                prosecutor: form.acusado,
                id: form.id
            }
            await action(obj, form.id)
        }


    }

    const moreVara = async () => {
        setters.setOffsetJuriVaras(states.offsetJuriVaras + states.limitJuriVaras);
    }



    const setTribunalFunction = async e => {
        const value = e.target.value;
        if (value === 'search') {
            await moreTribunal()
        } else {
            const resp = await loadVara(value, 0)
            if (!resp) {
                setters.setDataJuriVaras([])
                toast.info('Não foi possível buscar as varas do tribunal selecionado. Selecione outro tribunal!')
            } else {
                setters.setLimitSearchJuriVaras(false)
            }

            setForm(() => {
                return { ...form, court_id: value }
            });
        }

    }

    const setVaraFunction = async e => {
        if (e.target.value == 'search') {
            await moreVara()
        } else {
            setForm(() => {
                return { ...form, vara: e.target.value }
            })
        }

    }


    const truncateText = (text, maxLength) => {
        if (text?.length > maxLength) {
            return text?.substring(0, maxLength) + '...';
        }
        return text;
    };



    const addAutorizacao = e => {
        if (isEditAuthorization) {
            setAuthorization(e)
            setIsEditAuthorization(false)
            setOpenAuthorization(false)

        } else {
            setAuthorization(e)
            setOpenAuthorization(false)
        }
    }

    const deleteAutorizacao = e => {
        setAuthorization({})
    }

    const updateAuthorization = e => {
        setAuthorization(e)
        setIsEditAuthorization(true)
        setOpenAuthorization(true)
    }

    const editAutorizacao = e => {
        setAuthorization(e)
        setIsEditAuthorization(false)
        setOpenAuthorization(false)
    }



    useEffect(() => {
        if (states.dataJuriTribunais.length > 0) {
            setDataSelectCourt(states.dataJuriTribunais)
        }
    }, [states.dataJuriTribunais])

    useEffect(() => {
        if (states.dataJuriVaras.length > 0) {
            setDataSelectVara(states.dataJuriVaras)
        }
    }, [states.dataJuriVaras])


    useEffect(() => {
        if (states.dataJuriVaras.length > 0) {
            vara(form.court_id)
        }
    }, [states.offsetJuriVaras])


    useEffect(() => {
        if (states.modalJuri === false) {
            Object.keys(form).map(item => {
                setForm(e => {
                    return { ...e, [item]: '' }
                })
            })
            Object.keys(errorForm).map(item => {
                setErrorForm(e => {
                    return { ...e, [item]: false }
                })
            })
            setters.setIsEditJuri(false)
            setters.setLimitSearchJuriVaras(false)
            setters.setLimitSearchJuriTribunais(false)
            setters.setOffsetJuriVaras(0)
            setters.setOffsetJuriTribunais(0)
            setters.setJuriAcoesProcesso({})
            setters.setDataJuriVaras([])
            setters.setDataJuriTribunais([])
            setOpenAuthorization(false)
            setAuthorization({})
            setIsEditAuthorization(false)
            setDataSelectCourt([])
            setNeedAuthorization(false)
            setErrorAuth(false)

        } else {
            if (Object.keys(states.JuriAcoesProcesso).length > 0) {
                setForm(() => {
                    return {
                        ...form,
                        acao: states.JuriAcoesProcesso.action_id,
                        numero: states.JuriAcoesProcesso.number,
                        titulo: states.JuriAcoesProcesso.caseTitle,
                        status: states.JuriAcoesProcesso.status,
                        tipo: states.JuriAcoesProcesso.caseType,
                        reu: states.JuriAcoesProcesso.defendant,
                        acusado: states.JuriAcoesProcesso.prosecutor,
                        court_id: states.JuriAcoesProcesso.court_id,
                        vara: states.JuriAcoesProcesso.courtSection,
                        juiz: states.JuriAcoesProcesso.judge,
                        dataAbertura: moment(states.JuriAcoesProcesso.filingDate).format('YYYY-MM-DD'),
                        descricao: states.JuriAcoesProcesso.caseDescription,
                        notas: states.JuriAcoesProcesso.notes,
                        isPublic: states.JuriAcoesProcesso.isPublic == 1 ? "true" : "false",
                        id: states.JuriAcoesProcesso.id
                    }
                })
            }
            if (states.JuriAcoes.auth === 1 && !states.isEditJuri) {
                setNeedAuthorization(true)
            }
        }
    }, [states.modalJuri]);

    return (
        <Transition.Root show={states.modalJuri} as={Fragment}>
            <Dialog
                as="div"
                className="relative z-10"
                onClose={() => setters.setModalJuri(false)}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0">
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div
                        className="flex min-h-full w-[100%] itens-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                            {/* Mexer na altura, só colocar o height na classname abaixo */}
                            <Dialog.Panel
                                className=" w-[80%] transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:p-6">
                                <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                                    <button
                                        type="button"
                                        className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                                        onClick={() => setters.setModalJuri(false)}>
                                        <span className="sr-only">Close</span>
                                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                    </button>
                                </div>
                                <div className='flex-col border-b border-gray-900/10'>
                                    <div className='p-3'>
                                        <h2 className="text-base font-semibold leading-7 text-gray-900">
                                            {states.isEditJuri === false ? 'Cadastrar' : 'Editar'} processo
                                        </h2>
                                        <p className="mt-2 text-sm leading-6 text-gray-600">
                                            {states.isEditJuri === false ? 'Preencha os campos abaixo para iniciar o cadastro de um novo processo' : 'Preencha os campos abaixo para editar um processo'}
                                        </p>
                                    </div>
                                </div>

                                <form className='pt-10 pl-10 pr-10' onSubmit={e => onSubmitForm(e)}>
                                    <div className="space-y-12">
                                        <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
                                            {/* LINHA 1 */}
                                            <div className="grid max-w-6xl grid-cols-1 gap-x-6 gap-y-6 sm:grid-cols-9 md:col-span-8">
                                                <div className="sm:col-span-3">
                                                    <label
                                                        htmlFor="title"
                                                        className="block text-sm font-medium leading-6 text-gray-900">
                                                        Número do processo
                                                    </label>
                                                    <div className="mt-2">
                                                        <div
                                                            className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 sm:max-w-md">
                                                            <input
                                                                type='text'
                                                                className="pl-2 block w-full  min-w-0 flex-1 rounded-none rounded-l-md rounded-r-md border-0 ring-1 ring-inset ring-gray-300 py-1.5 text-gray-900 placeholder:text-gray-400  focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                                                                placeholder='Digite o número do processo'
                                                                onChange={e => setForm(() => {
                                                                    return { ...form, numero: e.target.value }
                                                                })}
                                                                value={form?.numero}
                                                            />
                                                        </div>
                                                    </div>
                                                    {errorForm.numero === true && <p class="mt-2 ml-2 text-pink-600 text-sm">
                                                        É obrigatório o preenchimento do número do processo.
                                                    </p>
                                                    }
                                                </div>
                                                <div className="sm:col-span-3">
                                                    <label
                                                        htmlFor="title"
                                                        className="block text-sm font-medium leading-6 text-gray-900">
                                                        Título do processo
                                                    </label>
                                                    <div className="mt-2">
                                                        <div
                                                            className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 sm:max-w-md">
                                                            <input
                                                                type='text'
                                                                className="pl-2 block w-full  min-w-0 flex-1 rounded-none rounded-l-md rounded-r-md border-0 ring-1 ring-inset ring-gray-300 py-1.5 text-gray-900 placeholder:text-gray-400  focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                                                                placeholder='Digite o número do processo'
                                                                onChange={e => setForm(() => {
                                                                    return { ...form, titulo: e.target.value }
                                                                }
                                                                )}
                                                                value={form?.titulo}
                                                            />
                                                        </div>
                                                    </div>
                                                    {errorForm.titulo === true && <p class="mt-2 ml-2 text-pink-600 text-sm">
                                                        É obrigatório o preenchimento do título do processo.
                                                    </p>
                                                    }
                                                </div>

                                                <div className="sm:col-span-3">
                                                    <label
                                                        htmlFor="title"
                                                        className="block text-sm font-medium leading-6 text-gray-900">
                                                        Status do processo
                                                    </label>
                                                    <div className="mt-2">
                                                        <div
                                                            className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 sm:max-w-md">
                                                            <select
                                                                className="pl-2 block w-full min-w-0 flex-1 rounded-none rounded-l-md rounded-r-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                                                                onChange={e => setForm(() => {
                                                                    return { ...form, status: e.target.value }
                                                                })}
                                                                value={form?.status}>
                                                                <option value={''}>Selecione ..</option>
                                                                <option value={'1'}>Aberto</option>
                                                                <option value={'2'}>Fechado</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    {errorForm.status === true && <p class="mt-2 ml-2 text-pink-600 text-sm">
                                                        É obrigatório o preenchimento do status do processo.
                                                    </p>
                                                    }
                                                </div>
                                            </div>
                                            {/* LINHA 2 */}
                                            <div className="grid max-w-6xl grid-cols-1 gap-x-6 gap-y-6 sm:grid-cols-9 md:col-span-8">

                                                <div className="sm:col-span-3">
                                                    <label
                                                        htmlFor="title"
                                                        className="block text-sm font-medium leading-6 text-gray-900">
                                                        Tipo do processo
                                                    </label>
                                                    <div className="mt-2">
                                                        <div
                                                            className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 sm:max-w-md">
                                                            <input
                                                                type='text'
                                                                className="pl-2 block w-full  min-w-0 flex-1 rounded-none rounded-l-md rounded-r-md border-0 ring-1 ring-inset ring-gray-300 py-1.5 text-gray-900 placeholder:text-gray-400  focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                                                                placeholder='Digite o tipo do processo'
                                                                onChange={e => setForm(() => {
                                                                    return { ...form, tipo: e.target.value }
                                                                })}
                                                                value={form?.tipo}
                                                            />
                                                        </div>
                                                    </div>
                                                    {errorForm.tipo === true && <p class="mt-2 ml-2 text-pink-600 text-sm">
                                                        É obrigatório o preenchimento do tipo do processo.
                                                    </p>
                                                    }
                                                </div>
                                                <div className="sm:col-span-3">
                                                    <label
                                                        htmlFor="title"
                                                        className="block text-sm font-medium leading-6 text-gray-900">
                                                        Sujeito passivo do processo
                                                    </label>
                                                    <div className="mt-2">
                                                        <div
                                                            className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 sm:max-w-md">
                                                            <input
                                                                type='text'
                                                                className="pl-2 block w-full  min-w-0 flex-1 rounded-none rounded-l-md rounded-r-md border-0 ring-1 ring-inset ring-gray-300 py-1.5 text-gray-900 placeholder:text-gray-400  focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                                                                placeholder='Digite o réu do processo'
                                                                onChange={e => setForm(() => {
                                                                    return { ...form, reu: e.target.value }
                                                                }
                                                                )}
                                                                value={form?.reu}
                                                            />
                                                        </div>
                                                    </div>
                                                    {errorForm.reu === true && <p class="mt-2 ml-2 text-pink-600 text-sm">
                                                        É obrigatório o preenchimento do sujeito passivo do processo.
                                                    </p>
                                                    }
                                                </div>
                                                <div className="sm:col-span-3">
                                                    <label
                                                        htmlFor="title"
                                                        className="block text-sm font-medium leading-6 text-gray-900">
                                                        Sujeito ativo do processo
                                                    </label>
                                                    <div className="mt-2">
                                                        <div
                                                            className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 sm:max-w-md">
                                                            <input
                                                                type='text'
                                                                className="pl-2 block w-full  min-w-0 flex-1 rounded-none rounded-l-md rounded-r-md border-0 ring-1 ring-inset ring-gray-300 py-1.5 text-gray-900 placeholder:text-gray-400  focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                                                                placeholder='Digite o acusado do processo'
                                                                onChange={e => setForm(() => {
                                                                    return { ...form, acusado: e.target.value }
                                                                })}
                                                                value={form?.acusado}
                                                            />
                                                        </div>
                                                    </div>
                                                    {errorForm.acusado === true && <p class="mt-2 ml-2 text-pink-600 text-sm">
                                                        É obrigatório o preenchimento do sujeito ativo do processo.
                                                    </p>
                                                    }
                                                </div>
                                            </div>
                                            {/* LINHA 3 */}
                                            <div className="grid max-w-6xl grid-cols-1 gap-x-6 gap-y-6 sm:grid-cols-9 md:col-span-8">
                                                <div className="sm:col-span-2">
                                                    <label
                                                        htmlFor="title"
                                                        className="block text-sm font-medium leading-6 text-gray-900">
                                                        Juiz do processo
                                                    </label>
                                                    <div className="mt-2">
                                                        <div
                                                            className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 sm:max-w-md">
                                                            <input
                                                                type='text'
                                                                className="pl-2 block w-full  min-w-0 flex-1 rounded-none rounded-l-md rounded-r-md border-0 ring-1 ring-inset ring-gray-300 py-1.5 text-gray-900 placeholder:text-gray-400  focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                                                                placeholder='Digite o juiz do processo'
                                                                onChange={e => setForm(() => {
                                                                    return { ...form, juiz: e.target.value }
                                                                })}
                                                                value={form?.juiz}
                                                            />
                                                        </div>
                                                    </div>
                                                    {errorForm.juiz === true && <p class="mt-2 ml-2 text-pink-600 text-sm">
                                                        É obrigatório o preenchimento do juiz do processo.
                                                    </p>
                                                    }
                                                </div>
                                                <div className="sm:col-span-2">
                                                    <label
                                                        htmlFor="title"
                                                        className="block text-sm font-medium leading-6 text-gray-900">
                                                        Data de abertura
                                                    </label>
                                                    <div className="mt-2">
                                                        <div
                                                            className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 sm:max-w-md">
                                                            <input
                                                                type='date'
                                                                className="pl-2 block w-full  min-w-0 flex-1 rounded-none rounded-l-md rounded-r-md border-0 ring-1 ring-inset ring-gray-300 py-1.5 text-gray-900 placeholder:text-gray-400  focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                                                                onChange={e => setForm(() => {
                                                                    return { ...form, dataAbertura: e.target.value }
                                                                }
                                                                )}
                                                                value={form?.dataAbertura}
                                                            />
                                                        </div>
                                                    </div>
                                                    {errorForm.dataAbertura === true && <p class="mt-2 ml-2 text-pink-600 text-sm">
                                                        É obrigatório o preenchimento da data de abertura do processo.
                                                    </p>
                                                    }
                                                </div>
                                                <div className="sm:col-span-2">
                                                    <label
                                                        htmlFor="title"
                                                        className="block text-sm font-medium leading-6 text-gray-900">
                                                        Tribunal
                                                    </label>
                                                    <div className="mt-2">
                                                        <div
                                                            className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 sm:max-w-md">
                                                            <select
                                                                id="oab"
                                                                name="oab"
                                                                className="pl-2 block w-full min-w-0 flex-1 rounded-none rounded-l-md rounded-r-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                                                                onChange={e => setTribunalFunction(e)}
                                                                value={form?.court_id}>
                                                                <option value={''}>Selecione ..</option>
                                                                {
                                                                    dataSelectCourt?.map((item, index) => {
                                                                        return (
                                                                            <option key={index} value={item.id}>{item.name}</option>
                                                                        )
                                                                    }
                                                                    )
                                                                }
                                                                {
                                                                    !states.limitSearchJuriTribunais &&
                                                                    <option value={'search'}>Buscar Mais ...</option>
                                                                }
                                                            </select>
                                                        </div>
                                                    </div>
                                                    {errorForm.court_id === true && <p class="mt-2 ml-2 text-pink-600 text-sm">
                                                        É obrigatório a seleção de um tribunal.
                                                    </p>
                                                    }
                                                </div>
                                                {
                                                    (form?.court_id !== '' && states.dataJuriVaras.length > 0) &&
                                                    <div className="sm:col-span-3">
                                                        <label
                                                            htmlFor="title"
                                                            className="block text-sm font-medium leading-6 text-gray-900">
                                                            Vara
                                                        </label>
                                                        <div className="mt-2">
                                                            <div
                                                                className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 sm:max-w-md">
                                                                <select
                                                                    id="oab"
                                                                    name="oab"
                                                                    className="pl-2 block w-full min-w-0 flex-1 rounded-none rounded-l-md rounded-r-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                                                                    onChange={e => setVaraFunction(e)}
                                                                    value={form?.vara}>
                                                                    <option value={''}>Selecione ...</option>
                                                                    {
                                                                        dataSelectVara?.map((item, index) => {
                                                                            return (
                                                                                <option key={index} value={item.id}>{item.name} - {item.email}</option>
                                                                            )
                                                                        }
                                                                        )
                                                                    }
                                                                    {
                                                                        !states.limitSearchJuriVaras &&
                                                                        <option value={'search'}>Buscar Mais ..</option>
                                                                    }
                                                                </select>
                                                            </div>
                                                        </div>
                                                        {errorForm.vara === true && <p class="mt-2 ml-2 text-pink-600 text-sm">
                                                            É obrigatório a seleção da vara.
                                                        </p>
                                                        }
                                                    </div>
                                                }
                                            </div>
                                            {/* LINHA 4 */}
                                            <div className="grid max-w-6xl grid-cols-1 gap-x-6 gap-y-6 sm:grid-cols-12 md:col-span-8">
                                                <div className="sm:col-span-4">
                                                    <label
                                                        htmlFor="title"
                                                        className="block text-sm font-medium leading-6 text-gray-900">
                                                        Descrição do processo
                                                    </label>
                                                    <div className="mt-2">
                                                        <div
                                                            className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 ">
                                                            <textarea
                                                                id="oab"
                                                                name="oab"
                                                                className="pl-2 block w-full min-w-0 flex-1 rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  focus:ring-blue-600 sm:text-sm sm:leading-6"
                                                                onChange={e => setForm(() => {
                                                                    return { ...form, descricao: e.target.value }
                                                                })}
                                                                value={form?.descricao}
                                                                placeholder='Digite a descrição do processo'
                                                            >
                                                            </textarea>
                                                        </div>
                                                    </div>
                                                    {errorForm.descricao === true && <p class="mt-2 ml-2 text-pink-600 text-sm">
                                                        É obrigatório o preenchimento da descrição do processo.
                                                    </p>
                                                    }
                                                </div>
                                                <div className="sm:col-span-4">
                                                    <label
                                                        htmlFor="title"
                                                        className="block text-sm font-medium leading-6 text-gray-900">
                                                        Notas do processo
                                                    </label>
                                                    <div className="mt-2">
                                                        <div
                                                            className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 ">
                                                            <textarea
                                                                id="oab"
                                                                name="oab"
                                                                className="pl-2 block w-full min-w-0 flex-1 rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  focus:ring-blue-600 sm:text-sm sm:leading-6"
                                                                onChange={e => setForm(() => {
                                                                    return { ...form, notas: e.target.value }
                                                                })}
                                                                value={form?.notas}
                                                                placeholder='Digite as notas do processo'
                                                            >
                                                            </textarea>
                                                        </div>
                                                    </div>
                                                    {errorForm.notas === true && <p class="mt-2 ml-2 text-pink-600 text-sm">
                                                        É obrigatório o preenchimento das notas do processo.
                                                    </p>
                                                    }
                                                </div>
                                                <div className="sm:col-span-4">
                                                    <label
                                                        htmlFor="title"
                                                        className="block text-sm font-medium leading-6 text-gray-900">
                                                        Visibilidade do processo
                                                    </label>
                                                    <div className="mt-2">
                                                        <div
                                                            className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 ">
                                                            <select className="pl-2 block w-full min-w-0 flex-1 rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  focus:ring-blue-600 sm:text-sm sm:leading-6"
                                                                onChange={e => setForm(() => {
                                                                    return { ...form, isPublic: e.target.value }
                                                                })}
                                                                value={form?.isPublic}>
                                                                <option value={''}>Selecione ..</option>
                                                                <option value={true}>Público</option>
                                                                <option value={false}>Privado</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    {errorForm.isPublic === true && <p class="mt-2 ml-2 text-pink-600 text-sm">
                                                        É obrigatório o preenchimento da visibilidade do processo.
                                                    </p>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {
                                        needAuthorization &&
                                        <div className="flex flex-col w-full">
                                            <div className="flex flex-col w-full items-between justify-between p-4">
                                                <div className="flex flex-row w-full items-between justify-between">
                                                    <p className="font-bold mb-2">Autorização de inclusão do processo</p>
                                                </div>
                                                <div>
                                                    {
                                                        !openAuthorization && (<p className={Object.keys(authorization).length === 0 ? "font-classic text-red-500" : "font-classic text-green-500"}>
                                                            {
                                                                (Object.keys(authorization).length === 0 && errorAuth) ? 'Nenhuma autorização cadastrada, obrigatório pelo menos uma autorização' : Object.keys(authorization).length > 0 && (

                                                                    <div className="flex flex-col">
                                                                        <div className="grid grid-cols-1 gap-4 mt-4">
                                                                            <div className="flex flex-col w-full items-between justify-between p-4 border rounded-[10px]">
                                                                                <div className="flex flex-row w-full items-between justify-between">
                                                                                    <XMarkIcon className="h-5 w-5 text-red-500" onClick={() => deleteAutorizacao()} />
                                                                                    <PencilIcon className="h-5 w-5 text-green-500" onClick={() => updateAuthorization(authorization)} />
                                                                                </div>
                                                                                <span className="font-bold mt-2" />
                                                                                <div className="flex flex-row w-full items-between justify-between">
                                                                                    <p className="font-bold mb-2">Título: {truncateText(authorization.title, 15)}</p>
                                                                                </div>
                                                                                <div className="flex flex-row w-full items-between justify-between">
                                                                                    <p className="font-bold mb-2 ">Descrição: {truncateText(authorization.description, 15)}</p>
                                                                                </div>
                                                                                <div className="flex flex-row w-full items-between justify-between">
                                                                                    <p className="font-bold mb-2">E-mail de envio: {authorization.email}</p>
                                                                                </div>
                                                                                <div className="flex flex-row w-full items-between justify-between">
                                                                                    <p className="font-bold mb-2">Arquivo: {authorization.file.name}</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                )

                                                            }
                                                        </p>)
                                                    }
                                                </div>
                                            </div>
                                            {
                                                (Object.keys(authorization).length === 0 || isEditAuthorization === true) ?
                                                    <AdicaoAutorizacao states={states} setters={setters} adicao={e => addAutorizacao(e)} isEdit={isEditAuthorization} authorization={authorization} edit={editAutorizacao} />
                                                    : <></>
                                            }
                                        </div>
                                    }
                                    <div className="mt-6 flex items-center justify-end gap-x-6">
                                        <button
                                            type="button"
                                            className="text-sm font-semibold leading-5 text-gray-900 bg-gray-100 rounded-md px-3 py-2 text-sm font-semibold shadow-sm hover:bg-gray-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-100"
                                            onClick={() => setters.setModalJuri(false)}>
                                            Cancelar
                                        </button>
                                        <button
                                            // hidden ={disabled}
                                            type="submit"
                                            className="rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600">
                                            Enviar
                                        </button>
                                    </div>
                                </form>

                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}
