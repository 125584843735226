
import { useEffect, useState } from "react";
import Table from '../../Tabela/TabelaDeferimentosProcessoJuridico'
import Modal from "../../Modal/DeferimentosJuri";
import ModalDeferimentoAction from '../../Modal/DeferimentoAction'

const DeferimentoProcesso = ({
    createData, getData, updateData,
    deleteData, getDataQuery, actionDeferimento,
    states, setters, dataAcaoProcesso
}) => {


    const headers = ['', 'Tipo', 'Descrição', 'Visibilidade', 'Nome do juíz', 'Requerente', 'Escritório | Advogado', 'Decisão', 'Data de inserção', 'Data da decisão', 'Data de atualização']
    const [search, setSearch] = useState('')



    const next = async () => {
        if (states.limitSearchJuriProcessoDeferimento && states.offsetJuriProcessoDeferimento > 0) {
            return;
        } else {
            setters.setOffsetJuriProcessoDeferimento(states.offsetJuriProcessoDeferimento + states.limitJuriProcessoDeferimento)
        }
    };

    const previous = async () => {
        const offset = states.offsetJuriProcessoDeferimento - states.limitJuriProcessoDeferimento
        if (offset < 0) {
            states.offsetJuriProcessoDeferimento < 0 && setters.setOffsetJuriProcessoDeferimento(0)
            return;
        } else {
            setters.setOffsetJuriProcessoDeferimento(states.offsetJuriProcessoDeferimento - states.limitJuriProcessoDeferimento)
        }
    };


    const openModal = async () => {
        await dataAcaoProcesso(states.JuriAcoes.id)
        setters.setIsEditJuri(false)
        setters.setModalDeferimento(true)
        setters.setJuriProcessoDeferimento({})
    }


    const loadData = async () => {
        if (search !== '') {
            await getDataQuery(states.JuriAcoes.id, search)
        } else {

            await getData(states.JuriAcoes.id)
        }
    }


    const openDefired = async (data) => {
        setters.setJuriProcessoDeferimento(data)
        setters.setModalDeferimentoAction(true)
    }



    const openModalEdit = async (data) => {
        setters.setIsEditJuri(true)
        setters.setJuriProcessoDeferimento(data)
        setters.setModalDeferimento(true)
    }



    const deleteProcess = async (data) => {
        setters.setIsEditJuri(false)
        setters.setJuriProcessoDeferimento(data)
        setters.setModalMsg(true)
        setters.setPerigoModalMsg(false)
        setters.setMsgModalMsg(`Deseja excluir a seção: ${data.titleSection}?`)
        setters.setDescModalMsg(`Após a confirmação a seção será excluída da ação.`)
        setters.setTitleButtonModalMsg('Confirmar')
        setters.setFunctionModalMsg(() => async () => {
            const response = await deleteData(states.JuriAcoes.id, data.id)
            setters.setModalMsg(false)
            if (response) {
                loadData()
            }
        })
        setters.setFunctionBtnModalMsg(() => async () => {
            setters.setModalMsg(false)
        })
    }


    const actionModal = async (data, id) => {
        let resp;
        if (states.isEditJuri) {
            resp = await updateData(states.JuriAcoes.id, data, id)
        } else {
            resp = await createData(states.JuriAcoes.id, data)
        }
        if (resp) {
            loadData()
        }
        setters.setJuriProcessoDeferimento({})
        setters.setModalDeferimento(false)

    }



    const loadSearch = async () => {
        setters.setLimitSearchJuriProcessoDeferimento(false)
        setters.setOffsetJuriProcessoDeferimento(0)
        if (search === '') {
            loadData()
        }
        await getDataQuery(states.JuriAcoes.id, search)
    }

    const loadForm = async () => {
        await dataAcaoProcesso(states.JuriAcoes.id)
    }




    useEffect(() => {
        loadData()

        return () => {
            setSearch('')
            setters.setDataJuriAcoesProcessoForm([]);
            setters.setDataJuriProcessoDeferimento([]);
            setters.setJuriProcessoDeferimento({});
            setters.setOffsetJuriProcessoDeferimento(0);
            setters.setLimitSearchJuriProcessoDeferimento(false);
            setters.setModalDeferimento(false);
        }

    }, [])

    useEffect(() => {
        if (states.dataJuriProcessoDeferimento.length > 0) loadData()

    }, [states.offsetJuriProcessoDeferimento])

    useEffect(() => {
        if (states.dataJuriAcoesProcessoForm.length > 0) loadForm()
    }, [states.offsetJuriAcoesProcesso])


    return (
        <>
            <div className="bg-white flex flex-col items-center justify-center bg-white border border-1 border-gray-200 rounded-md overflow-hidden bg-white p-4 mt-4">
                <h1 className='text-xl font-semibold'>Busca por movimentações</h1>
                <div className='flex  flex-col items-center justify-center mt-2 w-full'>
                    <input placeholder='Digite dado da atualização para realizar a busca' type="text" onChange={e => setSearch(e.target.value)}
                        value={search} className="border border-gray-300 px-2 py-1 rounded focus:outline-none focus:border-blue-500  w-[35%]"
                        onKeyDown={e => {
                            if (e.key === 'Enter') {
                                loadSearch()
                            }
                        }}
                    />
                    <div className="flex items-center md:ml-5 p-2">
                        <button className="bg-blue-500 hover:bg-blue-600 text-white py-1 font-bold px-8 rounded" onClick={loadSearch}>
                            Aplicar
                        </button>
                        {
                            states.isLoading && (
                                <div className="flex items-center justify-center ml-8">
                                    <div className="h-8 w-8 animate-spin rounded-full border-t-2 border-b-2 border-gray-500"></div>
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>
            <Table defired={openDefired} add={openModal} edit={openModalEdit} deleteDefer={deleteProcess} title={`Movimentações cadastrados na ação`}
                description={`Registro dos deferimentos que compõem a ação`} next={next} previous={previous} states={states} headers={headers}
                infoTable={{ offset: states.offsetJuriProcessoDeferimento, limit: states.limitJuriProcessoDeferimento }} dataTable={states.dataJuriProcessoDeferimento} />
            <Modal setters={setters} states={states} action={actionModal} />
            <ModalDeferimentoAction action={actionDeferimento} states={states} setters={setters} />
        </>
    )
}



export default DeferimentoProcesso;