import NavBar from "../../../../Components/Navbar/navbar";
import SpinnerOverlay from "../../../../Components/SpinnerOverlay";
import { useNavigate, useParams } from "react-router-dom";
import { InformationCircleIcon } from '@heroicons/react/20/solid'
import { AuthContext } from "../../../../Context";
import { AuthContextJuri } from "../../../../Context/juri";
import { useContext, useEffect, useState } from "react";
import Cookies from 'js-cookie'
import Footer from "../../../../Components/Footer/footer";
import ShowAcao from "../../../../Components/juridico/Acao";
import Table from '../../../../Components/Tabela/TabelaAcaoProcessosJuridico'
import TableAssociado from '../../../../Components/Tabela/TabelaAcaoProcessosJuridicoAssociado'
import ModalProcesso from "../../../../Components/Modal/ProcessoAcao";
import ModalConfirm from "../../../../Components/Modal/Confirm";
import Secoes from "../../../../Components/juridico/Secoes";
import Atualizacoes from "../../../../Components/juridico/Atualizacoes";
import Movimentacoes from "../../../../Components/juridico/Movimentacoes";
import ModalAcaoTrocaTribunal from '../../../../Components/Modal/AcaoTrocaTribunal'
import Arquivos from "../../../../Components/juridico/Arquivos";

const AcoesJuridico = () => {

    const { states, setters } = useContext(AuthContext);
    const {
        checkToken,
        dataAcaoProcesso,
        dataSystemAcaoProcesso,
        dataVaraForm,
        dataVaraAcaoCadastro,
        createLegalProcessCompleteAction,
        dataAcaoById,
        updateLegalProcess,
        lockUnlockLegalProcess,
        deleteLegalProcess,
        dataAcaoProcessoAssociados,
        dataAcaoProcessoAssociadosQuery,
        dataAcaoProcessoForm,
        dataAcaoSecao,
        createAcaoSecao,
        updateAcaoSecao,
        deleteAcaoSecao,
        dataAcaoAtualizacaoQuery,
        dataAcaoAtualizacao,
        createAcaoAtualizacao,
        updateAcaoAtualizacao,
        deleteAcaoAtualizacao,
        dataTribunalForm,
        actionDeferimento,
        deleteDeferimento,
        updateDeferimento,
        createDeferimento,
        dataProcessoDeferimentos,
        getProcessosDeferimentosQuery,
        dataAcaoArquivos,
        deleteAcaoArquivos,
        reprocessActionFile,
        processActionFile
    } = useContext(AuthContextJuri);

    const { id } = useParams()
    const hist = useNavigate()



    const checkTokenLoad = async () => {
        const cookie = Cookies.get('tokenJuri')
        if (cookie) {
            await checkToken()
        } else {
            setters.setIsSignedJuri(false)
            setters.setInsertCode(false)
            hist('/perfil')
        }
    }

    const [title, setTitle] = useState('')
    const [description, setDescription] = useState('')
    const [del, setDel] = useState(false)
    const [show, setShow] = useState('processos')
    const [search, setSearch] = useState('')

    const loadData = async () => {
        const resp = await dataAcaoById(id)
        if (!resp) {
            hist(`/juridicoIndex/acoes`)
        }
        await dataSystemAcaoProcesso(id)
        await dataAcaoProcesso(id)
    }



    //AQUI ATE O NEXT E PREVIOUS ESTAO EM FALTA, DEVE SER REALIZADO
    const headers = ['', 'Número', 'Título', 'Tipo', 'Juíz', 'Tribunal', 'Vara', 'Sujeito Ativo', 'Sujeito Passivo', 'Data de Início', 'E-mail criador', 'Data de criação', 'Data de atualização']
    const headersAssociado = ['Matrícula', 'Nome', 'CPF', 'E-Mail', 'Ação', 'Número Processo', 'Vara', 'Data de criação', 'Data de atualização']

    const next = async () => {
        if (states.limitSearchJuriAcoesProcesso && states.offsetJuriAcoesProcesso > 0) {
            setters.setLimitSearchJuriAcoesProcesso(false)
            return;
        } else {
            setters.setOffsetJuriAcoesProcesso(states.offsetJuriAcoesProcesso + states.limitJuriAcoesProcesso)
        }
    };

    const previous = async () => {
        const isLowerZero = states.offsetJuriAcoesProcesso - states.limitJuriAcoesProcesso
        if (isLowerZero < 0) {
            return;
        } else {
            setters.setOffsetJuriAcoesProcesso(states.offsetJuriAcoesProcesso - states.limitJuriAcoesProcesso)
        }
    };

    const nextAssociate = async () => {
        if (states.limitSearchJuriAcoesProcessoAssociados && states.offsetJuriAcoesProcessoAssociados > 0) {
            setters.setLimitSearchJuriAcoesProcessoAssociados(false)
            return;
        } else {
            setters.setOffsetJuriAcoesProcessoAssociados(states.offsetJuriAcoesProcessoAssociados + states.limitJuriAcoesProcessoAssociados)
        }
    };

    const previousAssociate = async () => {
        const isLowerZero = states.offsetJuriAcoesProcessoAssociados - states.limitJuriAcoesProcessoAssociados
        if (isLowerZero < 0) {
            return;
        } else {
            setters.setOffsetJuriAcoesProcessoAssociados(states.offsetJuriAcoesProcessoAssociados - states.limitJuriAcoesProcessoAssociados)
        }
    };
    //NECESSARIO REALIZAR PARA FUNCIONAR

    const addProcesso = async () => {
        await dataTribunalForm();
        // await dataVaraForm(states.JuriAcoes.court_id)
        setters.setModalJuri(true)
    }

    const editProcesso = async (data) => {
        setters.setIsEditJuri(true)
        setters.setJuriAcoesProcesso(data)
        await dataTribunalForm()
        await dataVaraForm(data.court_id)
        setters.setModalJuri(true)
    }



    const actionProcess = async (data, id) => {
        if (states.isEditJuri) {
            const resp = await updateLegalProcess(data, id)
            if (resp) {
                setters.setModalJuri(false)
                await dataAcaoProcesso(states.JuriAcoes.id)
            }
        } else {
            const resp = await createLegalProcessCompleteAction(data)
            if (resp) {
                setters.setModalJuri(false)
                await dataAcaoProcesso(states.JuriAcoes.id)
            }
        }
    }

    const openlockUnlock = async (data) => {
        setDel(false)
        if (data.status == 1) {
            setTitle('Encerrar processo')
            setDescription('Deseja realmente encerrar o processo?')
        } else {
            setTitle('Reabrir processo')
            setDescription('Deseja realmente reabrir o processo?')
        }
        setters.setConfirmModal(true)
        setters.setJuriAcoesProcesso(data)
    }

    const actionDelete = async () => {
        if (del === true) {
            const resp = await deleteLegalProcess(states.JuriAcoesProcesso.id)
            if (resp) {
                loadData()
            }
            setters.setConfirmModal(false)
            setters.setJuriAcoesProcesso({})
            return;
        }
        const id = await lockUnlockLegalProcess(states.JuriAcoesProcesso.id)
        if (id) {
            loadData()
        }
        setters.setConfirmModal(false)
        setters.setJuriAcoesProcesso({})
    }

    const openDeleteProccess = async (data) => {
        setDel(true)
        setTitle('Excluir processo')
        setDescription('Deseja realmente excluir o processo? Isso irá excluir todos os dados relacionados a ele.')
        setters.setConfirmModal(true)
        setters.setJuriAcoesProcesso(data)
    }

    const changeShowAssociates = async () => {
        setters.setDataJuriAcoesProcessoAssociados([])
        setters.setOffsetJuriAcoesProcessoAssociados(0)
        setters.setLimitSearchJuriAcoesProcessoAssociados(false)
        setters.setOffsetJuriAcoesProcesso(0)
        setters.setLimitSearchJuriAcoesProcesso(false)
        if (show === 'associado') {
            await dataAcaoProcesso(id)
            setShow('processos')
        } else {
            await dataAcaoProcessoAssociados(id)
            setShow('associado')
        }
    }

    const changeShowSecoes = async () => {
        if (show === 'secoes') {
            setShow('processos')
        } else {
            await dataAcaoProcesso(id)
            setShow('secoes')
        }
    }

    const changeShowAtualizacoes = async () => {
        if (show === 'atualizacoes') {
            setShow('processos')
        } else {
            await dataAcaoProcesso(id)
            setShow('atualizacoes')
        }
    }

    const changeShowMovimentação = async () => {
        if (show === 'movimentacoes') {
            setShow('processos')
        } else {
            await dataAcaoProcesso(id)
            setShow('movimentacoes')
        }
    }

    const changeShowArquivos = async () => {
        if (show === 'arquivos') {
            setShow('processos')
        } else {
            await dataAcaoProcesso(id)
            setShow('arquivos')
        }
    }


    const loadTribunal = async () => {
        await dataTribunalForm()
    }


    const moreTribunal = async () => {
        setters.setOffsetJuriTribunais(states.offsetJuriTribunais + states.limitJuriTribunais)
    }

    const loadSearch = async () => {
        setters.setOffsetJuriAcoesProcessoAssociados(0)
        if (search === '') {
            await dataAcaoProcessoAssociados(id)
            return;
        } else {
            await dataAcaoProcessoAssociadosQuery(id, search)
        }
    }

    const loadNextAssociate = async () => {
        if (search === '') {
            await dataAcaoProcessoAssociados(id)
            return;
        } else {
            await dataAcaoProcessoAssociadosQuery(id, search)
        }
    }


    const processFile = async (data) => {
        setters.setIsEditJuri(false)
        setters.setJuriProcessoDeferimento(data)
        setters.setModalMsg(true)
        setters.setPerigoModalMsg(false)
        setters.setMsgModalMsg(`Deseja realizar o processamento de arquivo da ação?`)
        setters.setDescModalMsg(`Após a confirmação o arquivo será reprocessado.`)
        setters.setTitleButtonModalMsg('Confirmar')
        setters.setFunctionModalMsg(() => async () => {
            const response = await processActionFile(states.JuriAcoes.id, { action_id: states.JuriAcoes.id })
            setters.setModalMsg(false)
            if (response) {
                loadData()
            }
        })
        setters.setFunctionBtnModalMsg(() => async () => {
            setters.setModalMsg(false)
        })
    }


    useEffect(() => {
        loadData()
        checkTokenLoad()


        return () => {
            setShow('processos')
            setters.setDataJuriAcoesProcessoAssociados([])
            setters.setJuriAcoesProcessoAssociados({})
            setters.setOffsetJuriAcoesProcessoAssociados(0)
            setters.setLimitSearchJuriAcoesProcessoAssociados(false)
            setters.setDataJuriVaras([])
            setters.setJuriVaras({})
            setters.setOffsetJuriVaras(0)
            setters.setLimitSearchJuriVaras(false)
            setters.setDataSystemJuriAcoesProcesso([])
            setters.setDataJuriAcoesProcessoForm([])
            setters.setDataJuriAcoesProcesso([])
            setters.setJuriAcoesProcesso({})
            setters.setOffsetJuriAcoesProcesso(0)
            setters.setLimitSearchJuriAcoesProcesso(false)
            setters.setModalAcoesProcesso(false)
            setters.setJuriAcoes({})
        }
    }, [])

    //CONFERIR AQUI SE ESTA COMPLETO, SE NAO ESTIVER, ENVIA PARA A PAGINA DE CONCLUSAO DE CADASTRO
    useEffect(() => {
        if (Object.keys(states.JuriAcoes).length > 0) {
            if (!states.JuriAcoes.isComplete) {
                hist('/juridicoIndex/acoes')
            }
        }
    }, [states.JuriAcoes])


    useEffect(() => {
        if (states.offsetJuriAcoesProcesso >= 0 && states.dataJuriAcoesProcesso.length > 0) dataAcaoProcesso(id)
    }, [states.offsetJuriAcoesProcesso])

    useEffect(() => {
        if (states.offsetJuriAcoesProcessoAssociados >= 0 && states.dataJuriAcoesProcessoAssociados.length > 0) loadNextAssociate()
    }, [states.offsetJuriAcoesProcessoAssociados])




    useEffect(() => {
        if (states.dataJuriTribunais.length > 0) loadTribunal()
    }, [states.offsetJuriTribunais])


    return (
        <>
            <div className="flex flex-1 flex-col pb-16 bg-white">
                <NavBar />
                <SpinnerOverlay />
                <div className='flex flex-1 flex-col md:pl-64 mb-4 mt-2'>
                    <div className="p-4">
                        <div className="px-4 sm:px-6 lg:px-8">
                            <div className="w-full flex justify-between mb-4">
                                <button onClick={() => hist('/juridicoIndex/acoes')} className="flex items-center border px-5 py-2 rounded-[10px] hover:shadow  hover:bg-blue-400  hover:text-white text-gray-700 ">
                                    <p className="text-sm">Voltar</p>
                                </button>
                            </div>
                            <div className="w-full flex justify-between mb-4">
                                <p className="text-md font-semibold text-gray-700">{states.JuriAcoes.name}</p>
                                <button className="border rounded-[10px] mt-2 sm:mt-0 sm:m-3 hover:shadow hover:text-white hover:bg-blue-500 text-gray-700  px-6 sm:px-4 py-2" onClick={processFile}>
                                    Gerar arquivo da ação
                                </button>
                            </div>
                            {
                                Object.keys(states.JuriAcoes).length > 0 &&
                                <ShowAcao acao={states.JuriAcoes} />
                            }
                            <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-4 mt-4">
                                {states.dataSystemJuriAcoesProcesso?.map((card, index) => {
                                    return (
                                        <div key={card.title} className={card.value > 0 && index === -1 ? "border border-1 border-gray-200 rounded-md overflow-hidden bg-white hover:shadow-md" : "border border-1 border-gray-200 rounded-md overflow-hidden bg-white hover:shadow-md"}>
                                            <div className="p-5">
                                                <div className="flex items-center">
                                                    <div className="flex-shrink-0">
                                                        {
                                                            card.value > 0 && index === -1 ? (<InformationCircleIcon className="w-6 h-6 text-red-400" aria-hidden="true" />) : (<InformationCircleIcon className="w-6 h-6 text-gray-400" aria-hidden="true" />)
                                                        }
                                                    </div>
                                                    <div className="ml-5 w-0 flex-1">
                                                        <dl>
                                                            {
                                                                card.value > 0 && index === -1 ? (<dt className="truncate text-sm font-medium text-red-500">{card.title}</dt>) : (<dt className="truncate text-sm font-medium text-gray-500">{card.title}</dt>)
                                                            }
                                                            <dd>
                                                                {
                                                                    card.value > 0 && index === -1 ?
                                                                        (<div className="text-lg font-medium text-red-500">
                                                                            {card.value}
                                                                        </div>) : (
                                                                            <div className="text-lg font-medium text-gray-900">
                                                                                {card.value}
                                                                            </div>)
                                                                }
                                                            </dd>
                                                        </dl>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>

                            <div className="flex w-full flex-col lg:flex-row items-center justify-between p-4 mt-4 ">
                                {
                                    states.JuriAcoes.internal === 0 &&
                                    <button className="border rounded-[10px] mt-2 sm:mt-0 sm:m-3 hover:shadow hover:text-white hover:bg-blue-500 text-gray-700 w-full px-6 sm:px-4 py-2" onClick={changeShowAssociates}>
                                        {show === 'associado' ? "Processos" : "Associados"}
                                    </button>
                                }
                                <button className="border rounded-[10px] mt-2 sm:mt-0 sm:m-3 hover:shadow hover:text-white hover:bg-blue-500 text-gray-700 w-full px-6 sm:px-4 py-2" onClick={changeShowSecoes}>
                                    {show === 'secoes' ? "Processos" : "Seções"}
                                </button>
                                <button className="border rounded-[10px] mt-2 sm:mt-0 sm:m-3 hover:shadow hover:text-white hover:bg-blue-500 text-gray-700 w-full px-6 sm:px-4 py-2" onClick={changeShowAtualizacoes}>
                                    {show === 'atualizacoes' ? "Processos" : "Atualizações"}
                                </button>
                                <button className="border rounded-[10px] mt-2 sm:mt-0 sm:m-3 hover:shadow hover:text-white hover:bg-blue-500 text-gray-700 w-full px-6 sm:px-4 py-2" onClick={changeShowMovimentação}>
                                    {show === 'movimentacoes' ? "Processos" : "Movimentações"}
                                </button>
                                <button className="border rounded-[10px] mt-2 sm:mt-0 sm:m-3 hover:shadow hover:text-white hover:bg-blue-500 text-gray-700 w-full px-6 sm:px-4 py-2" onClick={changeShowArquivos}>
                                    {show === 'arquivos' ? "Processos" : "Arquivos"}
                                </button>
                            </div>
                            {
                                (show === 'associado') ? (
                                    <>
                                        <div className="bg-white flex flex-col items-center justify-center bg-white border border-1 border-gray-200 rounded-md overflow-hidden bg-white p-4 mt-4">
                                            <h1 className='text-xl font-semibold'>Busca por associados</h1>
                                            <div className='flex flex-col items-center justify-center mt-2 w-full'>
                                                <input placeholder='Digite o dado do associado para realizar a busca' type="text" onChange={e => setSearch(e.target.value)}
                                                    value={search} className="border border-gray-300 px-2 py-1 rounded focus:outline-none focus:border-blue-500  w-[35%]"
                                                    onKeyDown={e => {
                                                        if (e.key === 'Enter') {
                                                            loadSearch()
                                                        }
                                                    }}
                                                />
                                                <div className="flex items-center md:ml-5 p-2">
                                                    <button className="bg-blue-500 hover:bg-blue-600 text-white py-1 font-bold px-8 rounded" onClick={loadSearch}>
                                                        Aplicar
                                                    </button>
                                                    {
                                                        states.isLoading && (
                                                            <div className="flex items-center justify-center ml-8">
                                                                <div className="h-8 w-8 animate-spin rounded-full border-t-2 border-b-2 border-gray-500"></div>
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <TableAssociado
                                            title={'Associados vinculados'} description={'Registro dos associados vinculados a essa ação'}
                                            next={nextAssociate} previous={previousAssociate} states={states} headers={headersAssociado}
                                            infoTable={{ offset: states.offsetJuriAcoesProcessoAssociados, limit: states.limitJuriAcoesProcessoAssociados }}
                                            dataTable={states.dataJuriAcoesProcessoAssociados} />
                                    </>
                                ) : (show === 'secoes') ? (
                                    <Secoes states={states} setters={setters}
                                        createSecao={createAcaoSecao} updateSecao={updateAcaoSecao} deleteSecao={deleteAcaoSecao} dataSecoes={dataAcaoSecao}
                                    />
                                ) : (show === 'atualizacoes') ? (
                                    <Atualizacoes states={states} setters={setters}
                                        createAtualizacao={createAcaoAtualizacao} updateAtualizacao={updateAcaoAtualizacao} deleteAtualizacao={deleteAcaoAtualizacao} dataAtualizacoes={dataAcaoAtualizacao}
                                        dataAtualizacoesQuery={dataAcaoAtualizacaoQuery} dataAcaoProcesso={dataAcaoProcessoForm}
                                    />
                                ) : (show === 'movimentacoes') ? (
                                    <Movimentacoes states={states} setters={setters}
                                        createData={createDeferimento} updateData={updateDeferimento} deleteData={deleteDeferimento} getData={dataProcessoDeferimentos} getDataQuery={getProcessosDeferimentosQuery}
                                        actionDeferimento={actionDeferimento} dataAcaoProcesso={dataAcaoProcessoForm}
                                    />
                                ) : (show === 'arquivos') ? (
                                    <Arquivos states={states} setters={setters} deleteData={deleteAcaoArquivos}
                                        getData={dataAcaoArquivos} reprocess={reprocessActionFile}
                                    />
                                ) :
                                    (

                                        <Table title='Processos cadastrados' description='Registro dos processos cadastrados para essa ação'
                                            next={next} previous={previous} states={states} headers={headers} action={states.JuriAcoes} edit={editProcesso}
                                            infoTable={{ offset: states.offsetJuriAcoesProcesso, limit: states.limitJuriAcoesProcesso }} dataTable={states.dataJuriAcoesProcesso}
                                            add={addProcesso} deleteProcess={openDeleteProccess} status={openlockUnlock}
                                        />
                                    )
                            }

                        </div>
                    </div>
                </div>
                <ModalProcesso states={states} setters={setters} moreTribunal={moreTribunal} action={actionProcess} vara={dataVaraForm} loadVara={dataVaraAcaoCadastro} />
                <ModalConfirm states={states} setters={setters} title={title} description={description} action={actionDelete} />
                <Footer />
            </div>
        </>
    )
}



export default AcoesJuridico;